import { Typography } from "@mui/material"
import CenteredContainer from "../Components/CenteredContainer"
import ReturnButton from "../Components/ArrowButton"
import DefaultBackground from "../Components/DefaultBackground"

/**
 * Renders an error message when the MSAL login fails.
 * @returns 
 */
export default function MsalError({ error }) {
    return (
        <DefaultBackground>
            <CenteredContainer>
                <Typography variant="h2" sx={{
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    marginBottom: "2.5rem",
                    marginTop: "1rem"
                }}>
                    An error occurred while trying to log you in.
                </Typography>
                <Typography variant="h5" color={"error"}>
                    {error?.errorMessage}
                </Typography>
                <ReturnButton url="/" text="Back home"/>
            </CenteredContainer>
        </DefaultBackground>
    )
}