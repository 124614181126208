import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { loginRequest } from "../authConfig.js";

export const useFetchWithMsal = () => {
  const { instance, accounts } = useMsal();

  const fetchData = async (apiUrl, options = null, override = false) => {
    const account = accounts[0]; // get the first account
    const request = {
      // contains the appropriate scopes
      ...loginRequest,
      account: account
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const fetchOptions = {
      ...options,
      headers: {
        ...options?.headers,
        'Authorization': `Bearer ${token}`
      }
    };

    return fetch(apiUrl, fetchOptions)
      .then(async (res) => {
          // return the response if the caller wants to override default "bad response" handling
          if (override || res.status.toString().startsWith('2')) {
            return res;
          }

          else {
            // default "bad response" handling is to throw a formatted error for the caller to catch
            const errorText = await res.text();
            throw new Error(`${res.statusText} (${res.status}): ${errorText}`);
          }
      })
  }

  return {
    fetchData: useCallback(fetchData, [accounts, instance])
  }
}