export const msalConfig = {
    auth: {
        // note the use of REACT_APP as a prefix. React exposes environment variables
        // with this prefix. See https://create-react-app.dev/docs/adding-custom-environment-variables/
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        // acts as https://localhost:3000/ when running locally
        // or <server-url>/ when running on public server
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
    scopes: [
        `api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`,
    ]
}