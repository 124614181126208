import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Tooltip from '@mui/material/Tooltip';

// A generic control to swap between grid displays
export default function SwapMeasurementsDisplay({
    swapMeasurementsDisplay,
    title = "Swap measurement display",
    placement = "left",
    sx = {}
}) {
    return (
        <Tooltip 
            placement={placement}
            title={title}
        >
            <IconButton 
                color="secondary" 
                aria-label={title} 
                onClick={swapMeasurementsDisplay}
                data-testid="swap-measurements-display"
                sx={{
                    ...sx,
                    padding: '0'
                }}
            >
                <SwapHorizIcon 
                    fontSize="large" 
                    color="primary"
                />
            </IconButton>
        </Tooltip>
    )
}