import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import '../Styles/card-display.css'

// An icon with different animations. Assumes the icon is from the MUI library.
const AnimatedIcon = ({
    Icon,
    loading
}) => {
    return (
        <Icon
            sx={{
                alignSelf:'flex-start',
                fontSize: '24'
            }} 
            color="primary"
            className={loading ? "rotating-icon" : "rolling-icon"}
        />
    )
}

// Displays some text and an animated icon in a card.
export default function CardDisplay ({
    text,
    loading,
    Icon
}
) {
    return (
        <Box 
            className="centralised-container"
            sx={{
                alignItems: 'center'
            }}
        >
            <Card 
                className="generic-card-display"
                elevation={3}
            >
                <CardContent
                    className="centralised-container"
                >
                    <Typography 
                        sx={{ 
                            fontSize: 24,
                            textAlign:'center'
                        }} 
                        variant='h2'
                        gutterBottom
                    >
                        {text}
                    </Typography>
                    <AnimatedIcon
                        Icon={Icon}
                        loading={loading}
                    />
                </CardContent>
            </Card>
        </Box>
    )
}
