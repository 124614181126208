import { Typography } from "@mui/material";
import CenteredContainer from "../Components/CenteredContainer";
import ReturnButton from "../Components/ArrowButton";
import DefaultBackground from "../Components/DefaultBackground";

/**
 * Renders a page when the path exists but the page is not yet implemented.
 * @returns 
 */
export default function UnderConstruction() {
    return (
        <DefaultBackground>
            <CenteredContainer>
                <Typography variant="h2" gutterBottom={true}>
                    This page is under construction.
                </Typography>
                <Typography variant="h5">
                    This feature is coming soon.
                </Typography>
                <ReturnButton url="/" text="Back home"/>
            </CenteredContainer>
        </DefaultBackground>
    );
}