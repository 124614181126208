
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useFetchWithMsal } from "../../Hooks/useFetchWithMsal"
import { Endpoints } from "../../Constants/Endpoints";
import { useServiceIds } from '../../Hooks/useServiceIds';
import { SiteIdContext } from '../../Contexts/SiteIdContext';
import { AlertSnackbarContext } from '../../Contexts/AlertSnackbarContext';
import Box from '@mui/material/Box';
// https://mui.com/x/react-date-pickers/adapters-locale/#set-a-custom-locale
import 'dayjs/locale/en-au';
import dayjs from 'dayjs';
import TimeSeriesSelectionsPanel from './Panels/Selections/TimeSeriesSelectionsPanel';
import TimeSeriesChartPanel from './Panels/TimeSeriesChartPanel';
import '../../Styles/panel.css';

export default function MeasurementTimeSeries() {
    // Hooks
    const { fetchData } = useFetchWithMsal(); // Fetch with MSAL as endpoints are protected
    const {
        serviceIds, selectedServiceId,
        handleServiceIdChange, getServiceIdsForSite
    } = useServiceIds(); // use service IDs

    // Contexts
    const selectedSiteId = useContext(SiteIdContext); // get the site ID from context
    const { setErrorMessage } = useContext(AlertSnackbarContext); // use the snackbar for errors

    // States
    const [ timeSeriesData, setTimeSeriesData ] = useState([]); // chart data
    const [ dataLoading, setDataLoading ] = useState(false);    // whether chart data is loading
    /**
     * Whether the chart data has been loaded.
     * Necessary for knowing when to attempt to display the chart,
     * or the card display. Using timeSeriesData's length is inadequate, as a legitimate
     * measurement may have a length of 0.
     */
    const [ dataLoaded, setDataLoaded ] = useState(false);      
    const [ startDate, setStartDate ] = useState(dayjs().add(-7, 'day'));   // start date
    const [ endDate, setEndDate ] = useState(dayjs());          // end date
    const [ selectedMeasurementTypes, setSelectedMeasurementTypes ] = useState(''); // measurement types to display
    const [ measurementTypes, setMeasurementTypes ] = useState([]); // measurement types for selected service ID
    const [ measurementTypesLoaded, setMeasurementTypesLoaded ] = useState(false);  // whether measurement types are loaded
    // used to ensure valid service ID/measurement types combinations trigger a data fetch
    const [ measurementTypesUpdated, setMeasurementTypesUpdated ] = useState(false); 

    // Helper functions

    // date validation
    const dateValidation = useCallback(() => {
        // check if dates are set
        if (!startDate || !endDate) {
            // no need for an error message, the user may not have selected a date yet
            return false;
        }

        // check if dates make sense
        if (startDate > endDate) {
            setErrorMessage('Start date cannot be after end date');
            return false;
        }

        else if (startDate > dayjs()) {
            setErrorMessage('Start date cannot be in the future');
            return false;
        }

        else {
            return true;
        }
    }, [startDate, endDate, setErrorMessage]);

    // Effects

    // get service IDs for selected site ID
    useEffect(() => {
        getServiceIdsForSite(fetchData, setErrorMessage, selectedSiteId)
    }, [getServiceIdsForSite, fetchData, setErrorMessage, selectedSiteId])

    // get measurement types for selected service ID
    useEffect(() => {
        /** 
         * Clear measurement types if service ID changes.
         * Two service IDs may not have the same measurement types,
         * which is bad practice for populating a Select element.
         */
        setSelectedMeasurementTypes('');
        setMeasurementTypesUpdated(false);

        // get measurement types for selected service ID
        if (selectedServiceId !== '') {
            setMeasurementTypesLoaded(false);

            fetchData(`${Endpoints.GetMeasurementTypesForService}?serviceId=${selectedServiceId}`)
            .then((res) => res.json())
            // set
            .then((measurementTypes) => {
                setMeasurementTypes(measurementTypes);
                setMeasurementTypesUpdated(true);
            })
            // display errors
            .catch((error) => {
                setErrorMessage(error.message);
            })
            // complete loading
            .finally(() => {
                setMeasurementTypesLoaded(true);
            })
        }
    }, [selectedServiceId, fetchData, 
        setMeasurementTypesLoaded, setMeasurementTypes, 
        setMeasurementTypesUpdated, setErrorMessage])

    // get measurement time series data
    useEffect(() => {
        // clear data if measurement types are not set or have been updated
        if (selectedMeasurementTypes === '' || !measurementTypesUpdated) {
            setDataLoaded(false);
        }

        // only fetch data if the required parameters are set
        else if (dateValidation()) {
            setDataLoading(true);
            // fetch with query parameters
            fetchData(
                Endpoints.GetMeasurementTimeSeries + 
                `?serviceId=${selectedServiceId}` + 
                `&measurementTypes=${selectedMeasurementTypes}` + 
                `&startDate=${startDate?.toISOString()}` + 
                `&endDate=${endDate?.toISOString()}`)
            .then((res) => res.json())
            .then((data) => {
                // format data before display
                const formattedData = data.map((item) => {
                    return {
                        ...item,
                        /** 
                         * Convert timestamp to unix timestamp
                         * This is necessary for compatibility with
                         * MUI LineChart
                         */
                        timestamp: dayjs(item.timestamp).unix()
                    }
                })

                // sort by timestamp
                formattedData.sort((a, b) => a.timestamp - b.timestamp);
                
                setTimeSeriesData(formattedData);
                setDataLoaded(true);
            })
            .catch((error) => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setDataLoading(false);
            })
        }
    }, [dateValidation, measurementTypesUpdated, selectedMeasurementTypes, 
        selectedServiceId, startDate, endDate, 
        fetchData, setTimeSeriesData, setErrorMessage])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
            <Box
                className='panel-container'
            >
                <TimeSeriesSelectionsPanel
                    serviceIds={serviceIds}
                    selectedSiteId={selectedSiteId}
                    selectedServiceId={selectedServiceId}
                    handleServiceIdChange={handleServiceIdChange}
                    selectedMeasurementTypes={selectedMeasurementTypes}
                    setSelectedMeasurementTypes={setSelectedMeasurementTypes}
                    measurementTypes={measurementTypes}
                    measurementTypesLoaded={measurementTypesLoaded}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <TimeSeriesChartPanel
                    timeSeriesData={timeSeriesData}
                    dataLoading={dataLoading}
                    dataLoaded={dataLoaded}
                    xAxisKey={'timestamp'}
                    yAxisKey={'realValue'}
                    upperLimitKey={'upperLimit'}
                    lowerLimitKey={'lowerLimit'}
                />
            </Box>
        </LocalizationProvider>
    )
}