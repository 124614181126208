import React, { useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ApplicationStatus from './ApplicationStatus';
import SiteIdSelection from './SiteIdSelection';
import { useMsal, useAccount } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useSiteIds } from '../Hooks/useSiteIds';
import { useFetchWithMsal } from '../Hooks/useFetchWithMsal';
import '../Styles/navigation-bar.css';


/**
 * The app bar at the top of the page. Contains the navigation menu.
 * @returns 
 */
export default function NavigationBar({ pages, selectedSiteId = "", setSelectedSiteId = () => {}, setErrorMessage = () => {} }) {
    // Get the account information.
    const account = useAccount();
    // Get the navigation function.
    const navigate = useNavigate();
    // Get the location
    const location = useLocation();
    // Get the authentication instance.
    const { instance } = useMsal();
    // Display the small menu if the screen is sufficiently small.
    const displaySmallMenu = useMediaQuery('(max-width:1200px)');
    // State for the navigation menu.
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    // Fetch with MSAL as endpoints are protected
    const { fetchData } = useFetchWithMsal();
    // Use the site IDs part of the hook
    const { siteIds, getAllSiteIds } = useSiteIds();

    // Run on render to retrieve site IDs
    useEffect(() => {
        getAllSiteIds(fetchData, setErrorMessage);
    }, [getAllSiteIds, fetchData, setErrorMessage]);
  
    // Open the navigation menu.
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    // Navigate when a menu item is clicked.
    const handleNavMenuItemClick = (path) => {
        setAnchorElNav(null);
        navigate(path);
    }
  
    // Close the navigation menu.
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // Logout the user.
    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <AppBar position="static">
            <Toolbar sx={{
                marginRight: '0rem'
            }}>
                <Box>
                    {displaySmallMenu ? 
                        ( <Box id="menu-small">
                            <IconButton aria-label="navigation menu" onClick={handleOpenNavMenu}>
                                <MenuIcon sx={{ color: 'white'}} fontSize="large"/>
                            </IconButton>
                            <Menu
                                sx={{ mt: '45px' }}
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                            >
                                {pages?.map((page) => (
                                        <MenuItem 
                                            aria-label={page.title}
                                            key={page.title} 
                                            onClick={() => handleNavMenuItemClick(page.path)}
                                        > 
                                            {page.title} 
                                        </MenuItem>
                                ))}
                            </Menu>
                        </Box> ) : (
                        <Box 
                            id="menu-large"
                            sx={{ 
                                display: 'flex',
                                flexDirection: 'row',
                                }}
                        >
                            {pages?.map((page) => (
                                <Tooltip key={page.title} title={"Go to " + page.title}>
                                    <Button
                                        key={page.title}
                                        onClick={() => {navigate(page.path)}}
                                        sx={{ 
                                            color: location.pathname === page.path ? 'black' : 'white', 
                                            background: location.pathname === page.path ? 'white' : 'none',
                                            marginRight: '0.5rem'
                                        }}
                                    >
                                        {page.title}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box
                    className="right-hand-navigation-container"
                >
                    <Box
                        className="site-id-selection-container"
                    >
                        <SiteIdSelection
                            siteIds={siteIds}
                            selectedSiteId={selectedSiteId}
                            handleSiteIdChange={
                                (event) => { 
                                    // Set the selected site ID to update the parent state holder
                                    setSelectedSiteId(event.target.value);
                                }
                            }
                        />
                    </Box>
                    <ApplicationStatus
                        selectedSiteId={selectedSiteId}
                    />
                    <Typography 
                        variant="h6"
                        sx={{
                            marginRight: "1rem",
                            fontSize: "1.1rem"
                        }}>
                        {account?.username}
                    </Typography>
                    <Tooltip title="Log out">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            onClick={handleLogout}
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip>  
                </Box>
            </Toolbar>
        </AppBar>
    );
}