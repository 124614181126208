import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useFetchWithMsal } from "../../Hooks/useFetchWithMsal"
import { useServiceIds } from '../../Hooks/useServiceIds';
import { Endpoints } from "../../Constants/Endpoints";
import { SiteIdContext } from '../../Contexts/SiteIdContext';
import { AlertSnackbarContext } from '../../Contexts/AlertSnackbarContext';
import Box from '@mui/material/Box';
// https://mui.com/x/react-date-pickers/adapters-locale/#set-a-custom-locale
import 'dayjs/locale/en-au';
import dayjs from 'dayjs';
import SpectraSelectionsPanel from './Panels/Selections/SpectraSelectionsPanel';
import SpectraChartPanel from './Panels/SpectraChartPanel';
import '../../Styles/panel.css';


export default function MeasurementSpectra() {
    // Hooks
    const { fetchData } = useFetchWithMsal(); // Fetch with MSAL as endpoints are protected
    const {
        serviceIds, selectedServiceId,
        handleServiceIdChange, getServiceIdsForSite
    } = useServiceIds(); // use service IDs

    // Contexts
    const selectedSiteId = useContext(SiteIdContext); // get the site ID from context
    const { setErrorMessage } = useContext(AlertSnackbarContext); // use the snackbar for errors

    // States
    const [ spectraData, setSpectraData ] = useState([]); // chart data
    const [ measurementParameters, setMeasurementParameters ] = useState([]); // measurement parameters grid data
    const [ testResults, setTestResults ] = useState([]); // test results grid data
    const [ dataLoading, setDataLoading ] = useState(false);  // whether data (chart/results) is loading
    const [ startDate, setStartDate ] = useState(dayjs().add(-7, 'day'));   // start date
    const [ endDate, setEndDate ] = useState(dayjs());          // end date
    const [ selectedSpectra, setSelectedSpectra ] = useState(''); // selected spectra identifier
    const [ spectraSelections, setSpectraSelections ] = useState([]); // spectra types
    const [ spectraSelectionsLoaded, setSpectraSelectionsLoaded ] = useState(false); // whether spectra types are loaded
    const [ measurementParametersDisplayed, setMeasurementParametersDisplayed ] = useState(false); // whether measurement parameters are displayed

    // Helper functions

    // date validation
    const dateValidation = useCallback(() => {
        // check if dates are set
        if (!startDate || !endDate) {
            // no need for an error message, the user may not have selected a date yet
            return false;
        }

        // check if dates make sense
        if (startDate > endDate) {
            setErrorMessage('Start date cannot be after end date');
            return false;
        }

        else if (startDate > dayjs()) {
            setErrorMessage('Start date cannot be in the future');
            return false;
        }

        else {
            return true;
        }
    }, [startDate, endDate, setErrorMessage]);

    // swap between measurement parameters and results
    const swapMeasurementsDisplay = () => {
        setMeasurementParametersDisplayed(!measurementParametersDisplayed);
    }

    // Effects

    // get service IDs for selected site ID
    useEffect(() => {
        getServiceIdsForSite(fetchData, setErrorMessage, selectedSiteId)
    }, [getServiceIdsForSite, fetchData, setErrorMessage, selectedSiteId])

    // get spectra types for selected service ID
    useEffect(() => {
        // clear selected spectra first
        setSelectedSpectra('');

        // get spectra types for selected service ID
        if (dateValidation() && selectedServiceId !== '') {
            setSpectraSelectionsLoaded(false);

            fetchData(Endpoints.GetSpectraSelectionsForService + 
                `?serviceId=${selectedServiceId}` + 
                `&startDate=${startDate?.toISOString()}` + 
                `&endDate=${endDate.toISOString()}`)
            .then((res) => res.json())
            // set
            .then((spectraSelections) => {
                setSpectraSelections(spectraSelections);
            })
            // display errors
            .catch((error) => {
                setErrorMessage(error.message);
            })
            // complete loading
            .finally(() => {
                setSpectraSelectionsLoaded(true);
            })
        }
    }, [dateValidation, selectedServiceId, fetchData, 
        setSpectraSelections, startDate, endDate,
        setErrorMessage, setSpectraSelectionsLoaded])


    // get measurement spectra data
    useEffect(() => {
        // only fetch data if the required parameters are set
        if (dateValidation() && selectedSpectra !== '') {
            setDataLoading(true);
            
            fetchData(`${Endpoints.GetMeasurementResults}?timeStamp=${encodeURIComponent(selectedSpectra)}`)
            .then((res) => res.json())
            .then((data) => {
                // get the raw sweep data 
                const sweepData = data.find((d) => d.parameterType === 'SweepData');
                const measurementParameters = data.filter((d) => d.parameterType === 'MeasurementParameter');
                const testResults = data.filter((d) => d.parameterType === 'TestResult');

                // produce an array of objects compatible with MUI LineChart
                const formattedSweepData = JSON.parse(sweepData.jsonValue).map((item) => {
                    return {
                        x: item[0],
                        y: item[1]
                    }
                });

                setSpectraData(formattedSweepData);
                setMeasurementParameters(measurementParameters);
                setTestResults(testResults);
            })
            .catch((error) => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setDataLoading(false);
            })
        }
    }, [dateValidation, selectedSpectra, 
        startDate, endDate, 
        fetchData, setErrorMessage])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
            <Box
                className='panel-container'
            >
                <SpectraSelectionsPanel
                    serviceIds={serviceIds}
                    selectedSiteId={selectedSiteId}
                    selectedServiceId={selectedServiceId}
                    handleServiceIdChange={handleServiceIdChange}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    selectedSpectra={selectedSpectra}
                    setSelectedSpectra={setSelectedSpectra}
                    spectraSelections={spectraSelections}
                    spectraSelectionsLoaded={spectraSelectionsLoaded}
                    measurementParameters={measurementParameters}
                    testResults={testResults}
                    swapMeasurementsDisplay={swapMeasurementsDisplay}
                    measurementParametersDisplayed={measurementParametersDisplayed}
                />
                <SpectraChartPanel
                    spectraData={spectraData}
                    measurementParameters={measurementParameters}
                    dataLoading={dataLoading}
                    xAxisKey='x'
                    yAxisKey='y'
                />
            </Box>
        </LocalizationProvider>
    )
}