import { useUpload } from '../Hooks/useUpload';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import '../Styles/upload.css';

/**
 * Contains the fields relevant to the upload,
 * including the file name and the ability to trigger
 * an upload dialogue.
 */
const UploadDialogue = ({
    file,
    setFile,
    setFormData,
    uploading,
    uploaded,
    setUploaded,
    disabled
}) => {
    // trigger the file upload dialogue for the user to select a file
    const triggerUploadDialogue = () => {
        document.getElementById('file-upload-input').click()
    };

    const uploadDisabled = disabled || uploading;

    return (
        <Box
            className="upload-dialogue-container"
        >
            <InputLabel
                htmlFor="file-upload-input"
                data-testid="file-upload-input-label"
            >
                <Button
                    data-testid="choose-file-button"
                    title="Choose file"
                    variant="outlined"
                    disabled={uploadDisabled}
                    color={uploaded ? "success" : "primary"}
                    onClick={triggerUploadDialogue}
                >
                    {
                        // null or undefined
                        file == null ? 
                            "Choose file"
                            :
                            file.name
                    }
                </Button>
            </InputLabel>
            <Input
                id="file-upload-input"
                data-testid="file-upload-input"
                type='file'
                componentsProps={{
                    input: {
                        accept: ".xlsx, .xls, .csv,"
                    }
                }}
                onChange={event => {
                    const file = event.target.files[0];
                    const formData = new FormData();
                    formData.append("file", file);

                    setFile(file);
                    setFormData(formData);
                    setUploaded(false);
                }}
                sx={{
                    display: 'none'
                }}
            />
            <Tooltip title="Choose file">
                <span>
                    <IconButton
                        disabled={uploadDisabled}
                        onClick={triggerUploadDialogue}
                        data-testid="choose-file-icon-button"
                    >
                        <UploadFileIcon
                            id="upload-file-icon"
                            color={
                                uploadDisabled ? 
                                    "rgba(0, 0, 0, 0.6)"
                                    : 
                                    uploaded ?
                                        "success"
                                        : 
                                        "primary"
                            }
                            fontSize='large'
                        />
                    </IconButton>
                </span>
            </Tooltip>
            {
                // if uploading, display a CircularProgress
                uploading && 
                <CircularProgress
                    data-testid="icon-progress-spinner"
                    color='success'
                    sx={{
                        // place around the UploadFileIcon
                        position: 'absolute',
                        zIndex: 0,
                        right: '7px',
                    }}
                    size={38}
                />
            }
        </Box>
    )
}

// Contains the upload button, which triggers the uploadFile function
const UploadControl = ({
    file,
    uploaded,
    uploading,
    uploadFile
}) => {
    return (
        <Box
            sx={{
                // to enable CircularProgress to take an absolute position
                position: 'relative'
            }}
        >
            <Button
                variant="contained"
                // change colour based on upload success
                color={uploaded ? "success" : "primary"}
                disabled={file === null || uploading}
                onClick={uploadFile}
            >
                Upload File
            </Button>
            {
                uploading && 
                <CircularProgress
                    data-testid="button-progress-spinner"
                    color='success'
                    className="upload-button-progress-spinner"
                    size={30}
                />
            }
        </Box>
    )
}

/**
 * Contains a stylised card with an upload dialogue and upload button.
 */
export default function Upload({
    fetchData,
    endpoint,
    disabled,
    setSuccessMessage,
    setErrorMessage,
    setParentLoading
}) {
    const { 
        file, setFile, setFormData, 
        uploading, uploaded, setUploaded,
        uploadFile
     } = useUpload();

    return (
        <Box
            className="upload-container"
        >
            <UploadDialogue
                file={file}
                setFile={setFile}
                setFormData={setFormData}
                uploading={uploading}
                uploaded={uploaded}
                setUploaded={setUploaded}
                disabled={disabled}
            />
            <UploadControl
                file={file}
                uploaded={uploaded}
                uploading={uploading}
                uploadFile={() => uploadFile(fetchData, endpoint, setParentLoading, setSuccessMessage, setErrorMessage)}
            />
        </Box>
    )
}