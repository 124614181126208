import { useState, useEffect, useContext } from 'react';
import { useFetchWithMsal } from "../../Hooks/useFetchWithMsal";
import { Endpoints } from "../../Constants/Endpoints";
import { SiteIdContext } from '../../Contexts/SiteIdContext';
import { AlertSnackbarContext } from '../../Contexts/AlertSnackbarContext';
import { MeasurementParametersWide, measurementParametersFieldValidation } from '../../Models/MeasurementParametersWide';
import { ScheduledTests, scheduledTestsFieldValidation } from '../../Models/ScheduledTests';
import GridAutoComplete from '../../Components/GridAutoComplete';
import Box from '@mui/material/Box';
import ServiceConfigurationHeader from './ServiceConfigurationHeader';
import ServiceConfigurationGrids from './ServiceConfigurationGrids';
import '../../Styles/ServiceConfiguration/service-configuration.css';

export default function ServiceConfiguration() {
    // Hooks
    const { fetchData } = useFetchWithMsal(); // Fetch with MSAL as endpoints are protected

    // Contexts
    const selectedSiteId = useContext(SiteIdContext); // get the site ID from context
    const { setErrorMessage, setSuccessMessage } = useContext(AlertSnackbarContext); // use the snackbar for errors

    // States
    const [measurementParametersCols, setMeasurementParametersCols] = useState(MeasurementParametersWide); // Columns for the Measurement Parameters grid
    const [scheduledTestCols, setScheduledTestCols] = useState(ScheduledTests); // Columns for the Scheduled Tests grid
    const [loading, setLoading] = useState(true); // Whether the service IDs are loading
    const [measurementParametersDisplayed, setMeasurementParametersDisplayed] = useState(true); // Whether the Measurement Parameters are displayed

    // Effects

    // On render, fetch the service IDs for the site to set the column definition.
    useEffect(() => {
        // If no site ID is selected, do not fetch the service IDs
        if (selectedSiteId === '' || selectedSiteId === null) {
            return;
        }

        setLoading(true);
        
        // Get Service IDs for site
        fetchData(`${Endpoints.GetSiteInformation}/${selectedSiteId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                // Get an array of options for the service ID column
                const serviceIdOptions = data.serviceIds?.map((serviceId) => serviceId.value);
                const pathIdOptions = data.pathIds?.map((pathId) => pathId.value);
                const testNameOptions = data.testNames?.map((testName) => testName.value);

                // Sets the Service ID column as a dropdown
                const addServiceIdDropdown = (col) => {
                    // modify the serviceId field
                    if (col.field === 'serviceId') {
                        // Set the value options to the service IDs
                        return {
                            ...col,
                            type: 'string',
                            renderEditCell: (params) => {
                                return (
                                    <GridAutoComplete
                                        params={params}
                                        options={serviceIdOptions}
                                    />
                                )
                            },
                            editable: true
                        }
                    }
                    return col;
                };

                // Sets the Path ID column as a dropdown
                const addPathIdDropdown = (col) => {
                    // modify the pathId field
                    if (col.field === 'pathId') {
                        // Set the value options to the path IDs
                        return {
                            ...col,
                            type: 'string',
                            renderEditCell: (params) => {
                                return (
                                    <GridAutoComplete
                                        params={params}
                                        options={pathIdOptions}
                                    />
                                )
                            },
                            editable: true
                        }
                    }
                    return col;
                }

                const addTestNamesDropdown = (col) => {
                    // modify the testName field
                    if (col.field === 'testName') {
                        // Set the value options to the test names
                        return {
                            ...col,
                            type: 'string',
                            renderEditCell: (params) => {
                                return (
                                    <GridAutoComplete
                                        params={params}
                                        options={testNameOptions}
                                        additionalOnChange={(value, params) => {
                                            // If the testName is changed, update the units
                                            const testName = value;
                                            const testToUnitMap = {
                                                "Carrier 3dB Bandwidth": "MHz",
                                                "Carrier to Noise Ratio": "dB",
                                                "Channel Power": "dBm",
                                                "EIRP": "dBW",
                                                "Noise Floor Level": "dBm"
                                            };
                                            const units = testToUnitMap[testName] ?? "";
                                            params.api.setEditCellValue({
                                                id: params.id,
                                                field: 'units',
                                                value: units
                                            });
                                        }}
                                    />
                                )
                            },
                            editable: true
                        }
                    }
                    return col;
                }

                setMeasurementParametersCols((prev) => {
                    return prev?.map(addPathIdDropdown);
                });

                setScheduledTestCols((prev) => {
                    return prev?.map(addServiceIdDropdown)
                                ?.map(addTestNamesDropdown);
                });
            }
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
        .finally(() => {
            setLoading(false);
        });
        
    }, [
        fetchData, selectedSiteId, 
        setMeasurementParametersCols, setScheduledTestCols, 
        setLoading, setErrorMessage
    ]);

    return (
        <Box
            className='service-configuration-parent-container'
        >
            <ServiceConfigurationHeader
                measurementParametersDisplayed={measurementParametersDisplayed}
                setMeasurementParametersDisplayed={setMeasurementParametersDisplayed}
                fetchData={fetchData}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                uploadDisabled={selectedSiteId === null || loading}
                setLoading={setLoading}
            />
            <ServiceConfigurationGrids
                selectedSiteId={selectedSiteId}
                loading={loading}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                measurementParametersDisplayed={measurementParametersDisplayed}
                measurementParametersCols={measurementParametersCols}
                scheduledTestCols={scheduledTestCols}
                measurementParametersFieldValidation={measurementParametersFieldValidation}
                scheduledTestsFieldValidation={scheduledTestsFieldValidation}
            />
        </Box>
    )
}