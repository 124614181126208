/**
 * Contains the title and path for each navigation item.
 */
export const NavigationItems = [
    {
        title: "Live Data",
        path: "/live-data"
    },
    {
        title: "PRTG Alarms",
        path: "/prtg-alarms"
    },
    {
        title: "Measurement Spectra",
        path: "/measurement-spectra"
    },
    {
        title: "Measurement Timeseries",
        path: "/measurement-timeseries"
    },
    {
        title: "Schedule Measurements",
        path: "/schedule-measurements"
    },
    {
        title: "Service Configuration",
        path: "/service-configuration"
    }
]