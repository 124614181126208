import { Box } from "@mui/material";

/**
 * A container centered vertically and horizontally.
 * Can optionally include a shadow.
 * @returns 
 */
export default function CenteredContainer({ shadow = true, ...props  }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50vh",
                width: "50vw",
                textAlign: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
                boxShadow: shadow ? "0 2px 6px rgba(0,0,0,0.2)" : "none"
            }}
        >
            {props.children}
        </Box>
    )
}