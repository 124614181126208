/**
 * The API endpoints. These are defined in the webapi project
 * in each controller.
 */
export const Endpoints = {
    MeasurementParametersRoot: "api/MeasurementParameters",
    GetMeasurementParametersForService: "api/MeasurementParameters/Service",
    MeasurementParametersWideRoot: "api/MeasurementParametersWide",
    GetMeasurementParametersWideForSite: "api/MeasurementParametersWide/Site",
    ScheduledTestsRoot: "api/ScheduledTests",
    GetScheduledTestsForSite: "api/ScheduledTests/Site",
    ServicesRoot: "api/Services",
    GetAllSiteIds: "api/Services/GetAllSiteIds",
    GetSiteInformation: "api/Site/SiteInformation",
    // Trailing slash to omit path parameter (site ID), which triggers get all
    GetAllServiceIds: "api/Services/SiteId/",
    // Provide Site ID as a path parameter here, that's why there's no trailing slash
    GetServiceIdsForSite: "api/Services/SiteId",
    GetMeasurementTypesForService: "api/Services/MeasurementTypes",
    GetSpectraSelectionsForService: "api/Services/SpectraSelections",
    RequestMeasurements: "api/MeasurementRequest/Request",
    GetLiveDataQueueLength: "api/MeasurementRequest/QueueLength",
    MeasurementScheduleRoot: "api/MeasurementSchedule",
    // Follows same notation as GetAllServiceIds/GetServiceIdsForSite
    GetAllScheduledMeasurements: "api/MeasurementSchedule/GetScheduledMeasurements/",
    GetScheduledMeasurementsForSite: "api/MeasurementSchedule/GetScheduledMeasurements",
    MeasurementDataRoot: "api/MeasurementData",
    GetMeasurementTimeSeries: "api/MeasurementData/TimeSeries",
    GetMeasurementResults: "api/MeasurementData/Results",
    UploadApplicationSettings: "api/ApplicationSettings/Upload",
}
