import { Fragment } from 'react';
import { Endpoints } from "../../Constants/Endpoints";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InfoTextDisplay from '../../Components/InfoTextDisplay';
import CMSDataGrid from '../../Components/CMSDataGrid';
import '../../Styles/ServiceConfiguration/service-configuration.css';

export default function ServiceConfigurationGrids ({
    selectedSiteId,
    loading,
    setErrorMessage,
    setSuccessMessage,
    measurementParametersDisplayed,
    measurementParametersCols,
    scheduledTestCols,
    measurementParametersFieldValidation,
    scheduledTestsFieldValidation
}) {
    return (
        selectedSiteId === '' || selectedSiteId === null ?
            <InfoTextDisplay
                text={"Please select a site to view data"}
            />
            :
            loading ?
                <CircularProgress
                    className='service-configuration-absolute-center'
                    size={70}
                />
                :
                <Box
                    className='service-configuration-parent-container'
                >
                    {
                        /**
                         * There is a reason we do not simply declare
                         * measurementParametersDisplayed ?
                         *  <CMSDataGrid ...> // measurement parameters grid
                         * :
                         *  <CMSDataGrid ...> // tests grid
                         * Doing this will cause issues when swapping grid display while editing. This is probably
                         * because the MUI Data Grid API is getting confused when the grid is unmounted and 
                         * then remounted with different information.
                         * The alternative is to keep both grids rendered at all times, and 'hide' one at a time.
                         * However, MUI complains about the parent container having a width of 0px, so this
                         * is not an option. See https://github.com/mui/mui-x/issues/4911
                         * The only resolution is to therefore conditionally render the grid in an odd way.
                         * Fragment is a generic React container, in theory anything can be used here.
                         */
                        measurementParametersDisplayed ? 
                            <CMSDataGrid
                                getUrl={`${Endpoints.GetMeasurementParametersWideForSite}/${selectedSiteId}`}
                                baseUrl={Endpoints.MeasurementParametersWideRoot}
                                columnsModel={measurementParametersCols}
                                pinColumns={true}
                                fieldToFocus={"serviceId"}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                                sortKey={"serviceId"}
                                testId='measurement-parameters-wide-grid'
                                validation={measurementParametersFieldValidation}
                                noRowsText='No measurement parameters found for this site'
                            />
                            :
                            <Fragment/>
                    }
                    {
                        !measurementParametersDisplayed ?
                            <CMSDataGrid
                                getUrl={`${Endpoints.GetScheduledTestsForSite}/${selectedSiteId}`}
                                baseUrl={Endpoints.ScheduledTestsRoot}
                                columnsModel={scheduledTestCols}
                                fieldToGroup={'serviceId'}
                                fieldToFocus={"serviceId"}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                                sortKey={"serviceId"}
                                testId='scheduled-tests-grid'
                                validation={scheduledTestsFieldValidation}
                                noRowsText='No scheduled tests found for this site'
                            />
                            :
                            <Fragment/>
                    }                              
                </Box> 
    )
}