/**
 * @fileOverview MeasurementResultsReceived Model
 * @module MeasurementResultsReceived
 * @exports MeasurementResultsReceived
 * @description Contains the model to be used with the MUI DataGrid to display Measurement Results received from SignalR.
 * This is essentially a description of each column.
 * See https://mui.com/x/api/data-grid/grid-col-def/
 */
export const MeasurementResultsReceived = [
    {
        // the data to read
        field: "parameterName",
        // the column header text
        headerName: "Name",
        // Fluid width, takes up the remaining space with a weight of 2
        flex: 2
    },
    {
        field: "value",
        headerName: "Value",
        flex: 1.25
    },
    {
        field: "units",
        headerName: "Units",
        flex: 1
    },
    {
        field: "lowerLimit",
        headerName: "Lower Limit",
        flex: 1.25
    },
    {
        field: "upperLimit",
        headerName: "Upper Limit",
        flex: 1.25
    }
]