import App from "../App";
import NotFound from "../Pages/NotFound";
import LiveData from "../Pages/LiveData/LiveData";
import RouteGuard from "../Components/RouteGuard";
import { RolesMatrix } from "./RolesMatrix";
import UnderConstruction from "../Pages/UnderConstruction";
import Home from "../Pages/Home";
import ScheduleMeasurements from "../Pages/ScheduleMeasurements/ScheduleMeasurements";
import MeasurementTimeSeries from "../Pages/MeasurementTimeSeries/MeasurementTimeSeries";
import MeasurementSpectra from "../Pages/MeasurementSpectra/MeasurementSpectra";
import ServiceConfiguration from "../Pages/ServiceConfiguration/ServiceConfiguration";
/**
 * The routes for the app. Each route has a path, an element, and may have children.
 */
export const RouteItems = [
    {
        path: "/",
        element: <App/>,
        // Render if the route is not found.
        errorElement: <NotFound/>,
        children: [
            {
                path: "",
                element: <Home/>
            },
            /**
             * Supposedly, this is a "bad example" of using layouts.
             * https://reactrouter.com/en/main/start/concepts#layout-routes
             * However, there's no way to specify a layout with variable props
             * in the Routes array. So, we have to do it this way. This way, 
             * we can specify the roles for each route in one place.
             */
            {
                path: "live-data",
                element: <RouteGuard roles={RolesMatrix.LiveData.Roles}><LiveData/></RouteGuard>
            },
            {
                path: "prtg-alarms",
                element: <UnderConstruction/>
            },
            {
                path: "measurement-spectra",
                element: <RouteGuard roles={RolesMatrix.MeasurementSpectra.Roles}><MeasurementSpectra/></RouteGuard>
            },
            {
                path: "measurement-timeseries",
                element: <RouteGuard roles={RolesMatrix.MeasurementTimeSeries.Roles}><MeasurementTimeSeries/></RouteGuard>
            },
            {
                path: "schedule-measurements",
                element: <RouteGuard roles={RolesMatrix.ScheduleMeasurements.Roles}><ScheduleMeasurements/></RouteGuard>
            },
            {
                path: "service-configuration",
                element: <RouteGuard roles={RolesMatrix.ServiceConfiguration.Roles}><ServiceConfiguration/></RouteGuard>
            } 
        ],
    }
]