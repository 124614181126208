import { Typography } from "@mui/material"
import LinearProgress from '@mui/material/LinearProgress';
import DefaultBackground from "../Components/DefaultBackground";
import CenteredContainer from "../Components/CenteredContainer"

/**
 * Renders a loading screen while the user is being redirected to the login page.
 * @returns 
 */
export default function MsalLoading() {
    return (
        <DefaultBackground>
            <CenteredContainer>
                <Typography variant="h2" sx={{
                    marginBottom: "2.5rem"
                }}>
                    Redirecting...
                </Typography>
                <LinearProgress sx={{ width: '35%' }}/>
            </CenteredContainer>
        </DefaultBackground>
    )
}