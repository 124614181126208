import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import '../Styles/selections.css';

/**
 * Contains the selections for a generic selection in the CMS database. 
 * This is always based on a site ID and service ID.
 */
export default function GenericCMSSelection({
    selectedSiteId,
    selectedServiceId,
    selectedValue,
    setSelectedValue,
    valueTypes,
    valueTypesLoaded,
    label,
    multiple = false,
    renderValue = (value) => value,
    selectionLimit
}) {
    /**
     * Chip display for multiple selections.
     * Adapted from https://mui.com/material-ui/react-select/#chip 
     */
    const MultiChipRender = (selected) => (
        <Box 
            sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 0.5
            }}>
            { 
                selected.map((value) => (
                    <Chip key={value} label={value} />
                ))
            }
            {
                selected.length === selectionLimit &&
                    <Tooltip
                        placement="right"
                        title={`Limited to ${selectionLimit} selections`}
                    >
                        <WarningIcon
                            color="warning"
                            sx={{
                                alignSelf: 'center'
                            }}
                        /> 
                    </Tooltip>
            }
        </Box>
    );

    return (
        <FormControl
            className='generic-selection'
        >
            <InputLabel 
                // turn something like "Measurement Spectra" to "measurement-spectra-label"
                id={label.split(' ')?.join('-')?.toLowerCase() + '-label'}
            >
                {label}
            </InputLabel>
            <Select
                labelId={label.split(' ')?.join('-')?.toLowerCase() + '-label'}
                value={
                    // multiple selection assumes value is a comma-separated string
                    multiple ?
                        selectedValue === '' ?
                            /**
                             * We transform it internally to an array. Arrays are
                             * nasty business as a useEffect dependency, often causing
                             * infinite rendering. We'd prefer to manage the array here,
                             * giving the parent freedom to use a string as a state and thereby
                             * a dependency in a useEffect hook.
                             */
                            []
                            :
                            selectedValue.split(',')
                        :
                        selectedValue
                }
                label={label}
                onChange={(event) => {
                        // if within the selection limit
                        if (multiple && event.target.value.length <= selectionLimit) {
                            // transform back to a comma-separated string
                            setSelectedValue(event.target.value.join(','));
                        }

                        // if not
                        else if (multiple) {
                            // remove the first element of the array
                            setSelectedValue(event.target.value.slice(1,).join(','));
                        }

                        else {
                            setSelectedValue(event.target.value);
                        }
                    }
                }
                renderValue={multiple ? MultiChipRender : renderValue}
                multiple={multiple}
            >
                {
                    // site ID must be selected
                    selectedSiteId === null || selectedSiteId === '' ?
                        <MenuItem disabled value={''}>Please select a Site ID</MenuItem>
                        :
                        (
                            // service ID must be selected
                            selectedServiceId === '' ?
                                <MenuItem disabled value={''}>Please select a Service ID</MenuItem>
                                :
                                (
                                    // display loading if value types are not loaded
                                    !valueTypesLoaded ?
                                        <MenuItem disabled value={''}>
                                            <LinearProgress
                                                sx={{
                                                    width: '95%'
                                                }}
                                            />
                                        </MenuItem>
                                        :
                                        // display options
                                        valueTypes?.length === 0 ?
                                            <MenuItem disabled value={''}>No {label.toLowerCase()} available</MenuItem>
                                            :
                                            valueTypes?.map((valueType) => {
                                                return (
                                                    <MenuItem 
                                                        key={valueType.value} 
                                                        value={valueType.value}
                                                    >
                                                        {valueType.value}
                                                    </MenuItem>
                                                )
                                            })
                                )
                        )
                }
            </Select>
        </FormControl>
    )
}