import '../Styles/default-background.css';

/**
 * A container for the background style. 
 * @returns 
 */
export default function DefaultBackground({ ...props}) {
    return (
        <div className="default-background">
            {props.children}
        </div>
    )
}