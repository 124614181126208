import { useState, useCallback } from 'react';
import { Severities } from '../Components/AlertSnackbar';

export const useSnackbar = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Whether or not the snackbar is open
    const [snackbarMessage, setSnackbarMessage] = useState(""); // The message to display in the snackbar
    const [alertDisplay, setAlertDisplay] = useState("Error"); // The title of the alert
    const [alertSeverity, setAlertSeverity] = useState(Severities.Error); // The severity of the alert

    /** 
     * Wrap in callback and specify dependencies to cache function definition.
     * Why? because setErrorMessage relies on this function. If we don't wrap it in useCallback,
     * then the stability of setErrorMessage is unknown by React, so it will force 
     * a rerender for anything that relies on it. Our useEffect hook in CMSDataGrid.js 
     * relies on it. This means that it will be rendered more often than we'd like, causing
     * weird visual bugs. See https://react.dev/reference/react/useCallback
     */
    const processSnackbarMessage = useCallback((message) => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
    }, [setSnackbarOpen, setSnackbarMessage]);

    // Wrap in callback to cache function definition for same reason as processSnackbarMessage.
    const setErrorMessage = useCallback((message) => {
        processSnackbarMessage(message);
        setAlertDisplay("Error");
        setAlertSeverity(Severities.Error);
    }, [setAlertDisplay, setAlertSeverity, processSnackbarMessage]);

    /**
     * Not referenced in a useEffect hook, but caching might help performance, despite the small overhead.
     * We know that the dependencies won't change, so there's not a lot of risk for re-caching.
     */
    const setWarningMessage = useCallback((message) => {
        processSnackbarMessage(message);
        setAlertDisplay("Warning");
        setAlertSeverity(Severities.Warning);
    }, [setAlertDisplay, setAlertSeverity, processSnackbarMessage]);
    
    const setSuccessMessage = useCallback((message) => {
        processSnackbarMessage(message);
        setAlertDisplay("Success");
        setAlertSeverity(Severities.Success);
    }, [setAlertDisplay, setAlertSeverity, processSnackbarMessage]);

    return {
        snackbarOpen: snackbarOpen,
        snackbarMessage: snackbarMessage,
        alertDisplay: alertDisplay,
        alertSeverity: alertSeverity,
        setSnackbarOpen: setSnackbarOpen,
        setErrorMessage: setErrorMessage, 
        setWarningMessage: setWarningMessage, 
        setSuccessMessage: setSuccessMessage 
    };
};