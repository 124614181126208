import Box from '@mui/material/Box';
import ServiceSelections from '../../../../Components/ServiceSelections';
import MeasurementTypeSelection from './MeasurementTypeSelection';
import BasicDatePicker from '../../../../Components/BasicDatePicker';
import '../../../../Styles/panel.css';

// Contains the selections for the Measurement Time Series page.
export default function TimeSeriesSelectionsPanel({
    serviceIds,
    selectedSiteId,
    selectedServiceId,
    handleServiceIdChange,
    selectedMeasurementTypes,
    setSelectedMeasurementTypes,
    measurementTypes,
    measurementTypesLoaded,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) {
    return (
        <Box
            className='selections-panel'
        >
            <ServiceSelections
                serviceIds={serviceIds}
                selectedSiteId={selectedSiteId}
                selectedServiceId={selectedServiceId}
                handleServiceIdChange={handleServiceIdChange}
            />
            <MeasurementTypeSelection
                selectedSiteId={selectedSiteId}
                selectedServiceId={selectedServiceId}
                selectedMeasurementTypes={selectedMeasurementTypes}
                setSelectedMeasurementTypes={setSelectedMeasurementTypes}
                measurementTypes={measurementTypes}
                measurementTypesLoaded={measurementTypesLoaded}
            />               
            <BasicDatePicker
                date={startDate}
                setDate={setStartDate}
                label="Start Date"
            />
            <BasicDatePicker
                date={endDate}
                setDate={setEndDate}
                label="End Date"
            />
        </Box>
    )
}