/**
 * @file LiveResultsPanel.js
 * @desc The live results panel. Contains the measurement image,
 * or a centre card that displays a message for the user.
 */

import Box from '@mui/material/Box';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import CardDisplay from '../../../Components/CardDisplay';
import '../../../Styles/LiveData/Panels/live-results-panel.css';
import '../../../Styles/panel.css';

// Displays the live image from a measurement.
const LiveImageDisplay = ({
    sweepData
}) => {
    return (
        <img 
            alt="measurement graph" 
            className="measurement-graph"
            // Get the image from the measurement data
            src={
                'data:image/png;base64,' +
                sweepData?.find((data) => {
                    // Retrieve the SAS URI from the SweepImage param
                    return data?.parameterName === "SweepImage"
                })?.value
            }
        />
    )
}

// What to display in the centre card based on the stage of measurement
const ProcessingMessage = ({ 
    measurementRequestProcessing, 
    measurementResultsProcessing, 
    selectedSiteId, 
    selectedServiceId 
}) => {
    // If the measurement request is processing
    if (measurementRequestProcessing) {
        return "Processing request...";
    }
    // If the measurement results are processing
    if (measurementResultsProcessing) {
        return "Running measurements...";
    }
    // If the site ID is not selected
    if (selectedSiteId === '') {
        return "Select a site!";
    }
    // If the service ID is not selected
    if (selectedServiceId === '') {
        return "Select a service!";
    }
    // Otherwise, display the default message
    return "Start a measurement!";
};

/** 
 * The centre card of the live results panel.
 * Displays a message with an animated icon.
 */
const LiveResultCard = ({
    measurementRequestProcessing, 
    measurementResultsProcessing, 
    selectedSiteId, 
    selectedServiceId 
}) => {
    return (
        <CardDisplay
            text={
                <ProcessingMessage
                    measurementRequestProcessing={measurementRequestProcessing}
                    measurementResultsProcessing={measurementResultsProcessing}
                    selectedSiteId={selectedSiteId}
                    selectedServiceId={selectedServiceId}
                />
            }
            loading={
                measurementRequestProcessing || measurementResultsProcessing
            }
            Icon={SatelliteAltIcon}
        />
    )
}

/**
 * The live results panel. Contains the measurement image,
 * or a centre card that displays a message for the user.
 */
export default function LiveResultsPanel ({
    measurementRequestProcessing, 
    measurementResultsProcessing, 
    selectedSiteId, 
    selectedServiceId,
    sweepData,
    continuousMeasurementProcessing
}) {
    return (
        <Box
            className='content-panel'
        >
            <Box 
                className="centralised-container"
                sx={{
                    alignItems: 'center'
                }}
            >
                {
                    sweepData.length > 0 && !measurementRequestProcessing && 
                        (!measurementResultsProcessing || continuousMeasurementProcessing) ? 
                    <LiveImageDisplay
                        sweepData={sweepData}
                    />
                        :
                    <LiveResultCard
                        measurementRequestProcessing={measurementRequestProcessing}
                        measurementResultsProcessing={measurementResultsProcessing}
                        selectedSiteId={selectedSiteId}
                        selectedServiceId={selectedServiceId}
                    />
                }
            </Box>
        </Box>
    )
}