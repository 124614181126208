import { Typography } from "@mui/material";
import CenteredContainer from "../Components/CenteredContainer";
import ReturnButton from "../Components/ArrowButton";
import DefaultBackground from "../Components/DefaultBackground";

/**
 * Renders a page when the user is not authorised to view a page.
 * @returns 
 */
export default function Unauthorised({ roles }) {
    return (
        <DefaultBackground>
            <CenteredContainer>
                <Typography variant="h2" gutterBottom={true}>
                    You are not authorised to view this content.
                </Typography>
                <Typography 
                    variant="h5"
                    sx={{
                        marginBottom: "1rem",
                    }}
                >
                    You are missing the roles: {
                        roles?.map((role, index) => {
                            return <Typography key={index} variant="h6" component="div" sx={{color: "red"}}>{role}</Typography>
                        
                        })
                    }
                </Typography>
                <Typography variant="h5">
                    Contact your website administrator for help.
                </Typography>
                <ReturnButton url="/" text="Back home"/>
            </CenteredContainer>
        </DefaultBackground>
    );
}