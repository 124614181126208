import { useState, useCallback } from 'react';
import { Endpoints } from '../Constants/Endpoints';

// Hook to use service IDs, for convenience
export const useServiceIds = () => {
    const [serviceIds, setServiceIds] = useState([]); // The list of service IDs
    const [selectedServiceId, setSelectedServiceId] = useState(''); // The selected service ID

    // we expect an autocomplete component to pass the value directly
    const handleServiceIdChange = useCallback((_event, value) => {
        setSelectedServiceId(value);
    }, [setSelectedServiceId]);

    // Get all service IDs for the selected site ID - runs when the selected site ID changes
    const getServiceIdsForSite = useCallback((fetchData, setErrorMessage, selectedSiteId) => {
        fetchData(Endpoints.GetServiceIdsForSite + `/${selectedSiteId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setServiceIds(data);
            }
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
    }, [setServiceIds]);

    return {
        serviceIds: serviceIds,
        selectedServiceId: selectedServiceId,
        handleServiceIdChange: handleServiceIdChange,
        getServiceIdsForSite: getServiceIdsForSite
    };
};