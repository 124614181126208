import { DatePicker } from '@mui/x-date-pickers-pro';
import '../Styles/selections.css'

// A date picker that acts as you would reasonably expect, with some basic styling.
export default function BasicDatePicker({
    date,
    setDate,
    label
}) {
    return (
        <DatePicker
            className='generic-selection'
            value={date}
            onChange={(newDate) => setDate(newDate)}
            label={label}
        />
    )
}