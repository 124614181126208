/**
 * @file MeasurementsSection.js
 * @desc Contains the Measurement Section. Contains the Measurements Grid display,
 * Measurements Grid and all the controls.
 */
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import CMSDataGrid from '../../../../Components/CMSDataGrid.js';
import ReadOnlyGrid from '../../../../Components/ReadOnlyGrid.js';
import MeasurementControls from './MeasurementControls.js';
import SwapMeasurementsDisplay from '../../../../Components/SwapMeasurementsDisplay.js';
import { MeasurementParameters } from '../../../../Models/MeasurementParameters.js';
import { UsedParameters } from '../../../../Models/UsedParameters.js';
import { MeasurementResultsReceived } from '../../../../Models/MeasurementResultsReceived.js';
import { Endpoints } from '../../../../Constants/Endpoints.js';
import '../../../../Styles/LiveData/Panels/Selections/measurements-section.css';

// The measurement parameters grid and its display
const MeasurementParametersGrid = ({
    selectedServiceId, 
    setErrorMessage, 
    setSuccessMessage, 
    setMeasurementParametersApiRef,
    swapMeasurementsDisplay,
    overrideParameters
}) => {
    return (
        <Fragment>
            <Box 
                display="flex" 
                alignItems="center"
            >
                <Typography 
                    sx={{ 
                        width: '95%'
                    }} 
                    noWrap 
                    variant="h4"
                    className={overrideParameters ? '': 'disabled-measurement-parameters-text'}
                >
                    Measurement Parameters
                </Typography>
                <Tooltip
                    placement="left"
                    title="These parameters can override local parameters"
                >
                    <HelpIcon
                        color="primary"
                    /> 
                </Tooltip>
                <SwapMeasurementsDisplay
                    swapMeasurementsDisplay={swapMeasurementsDisplay}
                />
            </Box>
            <CMSDataGrid
                getUrl={Endpoints.GetMeasurementParametersForService + `?serviceId=${encodeURIComponent(selectedServiceId)}`}
                baseUrl={Endpoints.MeasurementParametersRoot}
                pageSize={5}
                columnsModel={MeasurementParameters}
                fieldToFocus={"value"}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                // this gives access to the measurement parameters data
                setApiRef={setMeasurementParametersApiRef}
                sortKey={'key'}
                addEnabled={false}
                actionsEnabled={overrideParameters ? true : false}
                testId={'measurement-parameters-grid'}
                className={overrideParameters ? '': 'disabled-measurement-parameters-grid'}
            />
        </Fragment>
    )
}

// The parameters grids
const ParametersGrids = ({ 
    usedParametersDisplayed, 
    selectedServiceId, 
    setErrorMessage, 
    setSuccessMessage, 
    setMeasurementParametersApiRef,
    swapMeasurementsDisplay,
    usedParameters,
    overrideParameters
}) => {
    // Selectively display the measurement parameter or results grid
    return usedParametersDisplayed ?
        <ReadOnlyGrid
            isResults={false}
            title="Used Parameters"
            description="These parameters were used to take the measurement"
            rows={usedParameters}
            columnModel={UsedParameters}
            idField="parameterName"
            dataTestId="used-measurement-parameters-grid"
            control={
                <SwapMeasurementsDisplay
                    swapMeasurementsDisplay={swapMeasurementsDisplay}
                />
            }
        />
        :
        <MeasurementParametersGrid
            selectedServiceId={selectedServiceId}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setMeasurementParametersApiRef={setMeasurementParametersApiRef}
            swapMeasurementsDisplay={swapMeasurementsDisplay}
            overrideParameters={overrideParameters}
        />
}

/**
 * The measurements section of the selections panel.
 * Allows the user to see measurement parameters and results,
 * as well as initiate measurements.
 */
export default function MeasurementsSection ({
    startMeasurements,
    updatingQueue,
    queueLength,
    updateQueueLength,
    swapMeasurementsDisplay,
    usedParametersDisplayed,
    selectedSiteId,
    selectedServiceId,
    setErrorMessage,
    setSuccessMessage,
    setMeasurementParametersApiRef,
    usedParameters,
    measurementResults,
    continuousSweep,
    setContinuousSweep,
    measurementResultsProcessing,
    stopMeasurements,
    setOverrideParameters,
    overrideParameters,
    continuousMeasurementProcessing
}) {
    return (
        <Box 
            sx={{
                width: '100%', 
                marginTop: '1rem'
            }}
        >
            <ParametersGrids
                usedParametersDisplayed={usedParametersDisplayed}
                selectedServiceId={selectedServiceId}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                setMeasurementParametersApiRef={setMeasurementParametersApiRef}
                swapMeasurementsDisplay={swapMeasurementsDisplay}
                usedParameters={usedParameters}
                overrideParameters={overrideParameters}
            />
            <MeasurementControls
                startMeasurements={startMeasurements}
                updatingQueue={updatingQueue}
                queueLength={queueLength}
                updateQueueLength={updateQueueLength}
                selectedSiteId={selectedSiteId}
                continuousSweep={continuousSweep}
                setContinuousSweep={setContinuousSweep}
                measurementResultsProcessing={measurementResultsProcessing}
                stopMeasurements={stopMeasurements}
                setOverrideParameters={setOverrideParameters}
                continuousMeasurementProcessing={continuousMeasurementProcessing}
            />
            <ReadOnlyGrid
                title="Test Results"
                rows={measurementResults}
                columnModel={MeasurementResultsReceived}
                idField="parameterName"
                dataTestId="measurement-results-grid"
            />
        </Box>
    )
}