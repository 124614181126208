/**
 * @fileOverview UsedParameters Model
 * @module UsedParameters
 * @exports UsedParameters
 * @description Contains the model to be used with CMSDataGrid for Used Parameters.
 * This is essentially a description of each column.
 * See https://mui.com/x/api/data-grid/grid-col-def/
 */
export const UsedParameters = [
    {
        // the data to read
        field: "parameterName",
        // the column header text
        headerName: "Parameter",
        // the width in pixels
        width: 250
    },
    {
        field: "value",
        headerName: "Value",
        width: 150,
        // whether this column is editable, omission means false
        editable: "false"
    },
    {
        field: "units",
        width: 150,
        headerName: "Units"
    }
]