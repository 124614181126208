import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Fragment } from 'react';
import '../Styles/results-grid.css';

// A read-only grid intended for displaying measurement results or parameters
export default function ReadOnlyGrid({
    isResults = true,
    title = "Results",
    description = "Contains measurement results",
    rows,
    columnModel,
    idField,
    dataTestId = "results-grid",
    control = null
}) {
    const measurementResultsError = isResults ? 
        rows.some(row => row.status && row.status !== 'Pass') : false;

    return (
        <Fragment>
            <Box 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%', 
                    marginTop: '1rem',
                    marginBottom: '0.5rem'
                }}
            >
            <Typography 
                sx={{width: '95%'}} 
                noWrap 
                variant="h4"
            >
                {title}
            </Typography>
            {
                <Tooltip
                    placement="left"
                    title={description}
                >
                    <HelpIcon
                        color="primary"
                    /> 
                </Tooltip>
            }
            {
                // only display the success/error icon if the grid is for measurement results
                isResults &&
                // display an error icon if there are any errors in the measurement results
                ( 
                    measurementResultsError ?
                        <Tooltip
                            placement="left"
                            title="Results grid has errors"
                        >
                            <ErrorIcon
                                color="error"
                            /> 
                        </Tooltip>
                        :
                        // otherwise, display a success icon
                        <Tooltip
                            placement="left"
                            title="Results grid has no errors"
                        >
                            <CheckCircleIcon
                                color="success"
                            />
                        </Tooltip>
                )
            }
            {
                // display a control if one is provided
                control
            }
            </Box>
            <DataGridPremium
                autoHeight
                rows={rows}
                columns={columnModel}
                /**
                 * Needs to be enabled.
                 * see https://mui.com/x/react-data-grid/pagination/#pagination-model
                 */
                pagination
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                getRowId={(row) => row[idField]}
                data-testid={dataTestId}
                getRowClassName={(params) => {
                    return isResults ?
                        params.row.status && params.row.status !== 'Pass' ? 'error-row' : ''
                        :
                        '';
                }}
            />
        </Fragment>
    )
}