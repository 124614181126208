import * as React from "react";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// An autocomplete component that can be used in a MUI DataGrid
export default function GridAutoComplete({ 
    params, 
    options,
    additionalOnChange = (_value, _params) => {}
}) {
    /** 
     * See https://github.com/mui/mui-x/issues/9406
     * Essentially, without this, users cannot use tab to navigate over this cell
     * in a grid.
     */
    const { hasFocus } = params;
    const inputRef = React.useRef();

    useEnhancedEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
        }
    }, [hasFocus]);
    return (
        <Autocomplete
            // convert empty string -> null
            value = { params.value === "" ? null : params.value }
            options = {options}
            renderInput = {(params) => 
                <TextField 
                    inputRef={inputRef}
                    {...params}
                />
            }
            onChange = {(_event, value) => {
                params.api.setEditCellValue({
                    id: params.id,
                    field: params.field,
                    value: value
                });

                additionalOnChange(value, params);
            }}
            sx={{
                width: '100%',
                fontSize: '12px',
                // remove the border
                " & .MuiOutlinedInput-notchedOutline": {
                    border: 'none'
                }
            }}
        />
    )
}