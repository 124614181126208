import Box from '@mui/material/Box';
import ServiceSelections from '../../../../Components/ServiceSelections';
import BasicDatePicker from '../../../../Components/BasicDatePicker';
import SwapMeasurementsDisplay from '../../../../Components/SwapMeasurementsDisplay';
import SpectraSelection from './SpectraSelection';
import { MeasurementResultsPure } from '../../../../Models/MeasurementResultsPure.js';
import ReadOnlyGrid from '../../../../Components/ReadOnlyGrid.js';
import '../../../../Styles/panel.css';

// Contains the selections for the Measurement Spectra page.
export default function SpectraSelectionsPanel({
    serviceIds,
    selectedSiteId,
    selectedServiceId,
    handleServiceIdChange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedSpectra,
    setSelectedSpectra,
    spectraSelections,
    spectraSelectionsLoaded,
    measurementParameters,
    testResults,
    swapMeasurementsDisplay,
    measurementParametersDisplayed
}) {
    return (
        <Box
            className='selections-panel'
        >
            <ServiceSelections
                serviceIds={serviceIds}
                selectedSiteId={selectedSiteId}
                selectedServiceId={selectedServiceId}
                handleServiceIdChange={handleServiceIdChange}
            />         
            <BasicDatePicker
                date={startDate}
                setDate={setStartDate}
                label="Start Date"
            />
            <BasicDatePicker
                date={endDate}
                setDate={setEndDate}
                label="End Date"
            />
            <SpectraSelection
                selectedSiteId={selectedSiteId}
                selectedServiceId={selectedServiceId}
                selectedSpectra={selectedSpectra}
                setSelectedSpectra={setSelectedSpectra}
                spectraSelections={spectraSelections}
                spectraSelectionsLoaded={spectraSelectionsLoaded}
            />
            <ReadOnlyGrid
                isResults={!measurementParametersDisplayed}
                rows={measurementParametersDisplayed? measurementParameters: testResults}
                columnModel={MeasurementResultsPure}
                idField="pk"
                dataTestId={
                    measurementParametersDisplayed ? 
                        "spectra-measurement-parameters-grid"
                        :
                        "spectra-measurement-results-grid"
                }
                title={
                    measurementParametersDisplayed ?
                        "Used Parameters"
                        :
                        "Test Results"
                }
                description={
                    measurementParametersDisplayed ?
                        "These parameters were used to take the measurement"
                        :
                        "Contains measurement results"
                }
                control={
                    <SwapMeasurementsDisplay
                        swapMeasurementsDisplay={swapMeasurementsDisplay}
                    />
                }
            />
        </Box>
    )
}