import '../Styles/home-page.css'
import { useState } from 'react'
import CenteredContainer from "../Components/CenteredContainer"
import { Fade } from '@mui/material'
import ArrowButton from "../Components/ArrowButton"

export default function Home() {
    const [swsLogoIn, setSwsLogoIn] = useState(false)
    const [buttonIn, setButtonIn] = useState(false)

    /**
     * The telstra logo fades in first. 
     * Then the softwire logo fades in.
     * Lastly, the start here button fades in.
     * Each are separated by the same amount of time and fade in over the same amount of time.
     */
    // The softwire logo fades in after 0.5 seconds.
    setTimeout(() => {
        setSwsLogoIn(true)
    }, 500);
    // The start here button fades in after 1 second.
    setTimeout(() => {
        setButtonIn(true)
    }, 1000);

    return (
        <CenteredContainer shadow={false}>
            <Fade in={true} timeout={1000}>
                <img className="logo" width="300px" alt="Telstra Logo" src={require("../Assets/telstra-logo.png")}/>
            </Fade>
            <Fade in={swsLogoIn} timeout={1000}>
                <img className="logo" width="300px" alt="Softwire Logo" src={require("../Assets/sws-logo.png")}/>
            </Fade>
            <Fade in={buttonIn} timeout={1000}>
                <div>
                    <ArrowButton marginTop={"0.5rem"} back={false} url={"live-data"} text={"Start here"}/>
                </div>
            </Fade>
        </CenteredContainer>
    )
}