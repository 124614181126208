import * as React from 'react';
import { Fragment } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import DeletePrompt from './DeletePrompt';
import {
  DataGridPremium,
  useGridApiRef,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons
} from '@mui/x-data-grid-premium';
import { StatusCodes } from 'http-status-codes';
import { useFetchWithMsal } from '../Hooks/useFetchWithMsal';
import { useDeletePrompt } from '../Hooks/useDeletePrompt';

/**
 * Renders a data grid component for CMS data.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.getUrl - The URL to fetch data from.
 * @param {string} props.baseUrl - The base URL for API requests.
 * @param {Array} props.columnsModel - The model for defining columns in the data grid.
 * @param {number} [props.pageSize=10] - The number of rows to display per page.
 * @param {string} props.fieldToFocus - The field to focus on when a row is in edit mode.
 * @param {function} props.setErrorMessage - The function to set an error message.
 * @param {function} props.setSuccessMessage - The function to set a success message.
 * @param {string} props.sortKey - The key to sort the rows by.
 * @param {function} [props.setApiRef=null] - The function to set the API reference for data access in the parent component.
 * @param {boolean} [props.addEnabled=true] - Whether the add action is enabled.
 * @param {boolean} [props.actionsEnabled=true] - Whether the actions column is enabled.
 * @param {string} [props.className=""] - The CSS class name for the component.
 * @param {string} [props.testId="cms-data-grid"] - The test ID for the component.
 * @param {Array} [props.newRowMap=[]] - The map of new rows to be added.
 * @param {function} [props.validation=() => ""] - The function to validate rows.
 * @param {string} [props.noRowsText="No rows"] - The text to display when there are no rows.
 * @param {boolean} [props.pinColumns=false] - Whether to pin columns.
 * @param {string} [props.fieldToGroup=null] - The field to group rows with.
 * @returns {JSX.Element} The CMSDataGrid component.
 */
export default function CMSDataGrid({ 
  getUrl, baseUrl, columnsModel, pageSize = 10, 
  fieldToFocus, setErrorMessage, setSuccessMessage, sortKey, 
  setApiRef = null, addEnabled = true, actionsEnabled = true,
  className = "", testId = "cms-data-grid", newRowMap = [], 
  validation = () => "", noRowsText = "No rows",
  pinColumns = false, fieldToGroup = null
}) {
  // References
  const apiRef = useGridApiRef();
  const { fetchData } = useFetchWithMsal();

  // States
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  // Delete prompt
  const { 
    deletePromptOpen, deleteIdentifierText, 
    trackDeleteConfirmation, handleDeletePromptOpen, handleDeletePromptClose 
  } = useDeletePrompt();


  /** 
   * Load data on first render.
   * Note that we include loadData() inside this hook
   * as it is not safe to omit functions from the list
   * of dependencies. So if loadData() was outside useEffect,
   * we would need to include it as a dependency.
   * Generally speaking, this isn't best practice as it is
   * easy to omit dependencies that the function may rely on.
   * In this case, something like baseUrl may be missed.
   * The best practice is to simply declare the function within the hook.
   * See https://legacy.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   */
  React.useEffect(() => {
    // load data for grid display
    const loadData = () => {
      // trigger loading animation
      setLoading(true);

      // fetch data
      fetchData(getUrl)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          // set the rows
          setRows(data);
          // set the api reference for data access in parent component
          if (setApiRef) {
            setApiRef(apiRef);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    }

    loadData();
  }, [ fetchData, getUrl, setLoading, apiRef, setApiRef, setErrorMessage ])

  // add actions column to the columns model
  const columns = actionsEnabled ? // only if actions are enabled
    columnsModel.concat({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      width: 75,
      getActions: ({ id }) => {
        // Don't give actions to grouped rows.
        const node = apiRef.current.getRowNode(id);
        if (node?.type === 'group') {
          return [];
        }

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
        // If editing, display save and cancel buttons
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        
        // Otherwise, display edit and delete buttons
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      }
    })
    :
    columnsModel;
  
  /** Handle button click functions **/
  
  // Prevents the row from exiting edit mode when the user clicks away
  const handleRowEditStop = (params, event) => {
    if (params?.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // When the edit button is clicked, the row is put into edit mode
  const handleEditClick = (pk) => () => {
    // update the row mode to edit mode
    setRowModesModel(({
      ...rowModesModel,
      [pk]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
    }));
  };

  /** 
   * When the save button is clicked, the row is saved.
   * Note that this function does not call the backend to
   * save changes, processRowUpdate handles that.
   */
  const handleSaveClick = (pk) => () => {
    // update the row mode to view mode
    setRowModesModel(({
      ...rowModesModel,
      [pk]: { mode: GridRowModes.View },
    }));
  };

  // Deletes the row with the given pk
  const processDelete = (pk) => {
    setLoading(true);

    // call the API to delete the row
    fetchData(`${baseUrl}/${pk}`, {
        method: 'DELETE'
    })
    .then((res) => {
      // if the request is successful, remove the row from the grid
      if (res.status === StatusCodes.OK || StatusCodes.NO_CONTENT) {
        // delete the row
        apiRef.current.updateRows([{ pk: pk, _action: 'delete' }])
        setSuccessMessage("Successfully deleted entry!")
      }

      // otherwise, display the error message
      else {
        res.json().then(result => {
          setErrorMessage(result.title);
        })
      }

    })
    // if the request fails, display the error message
    .catch((error) => {
      setErrorMessage(error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  // return text to identify a row
  const identifyRow = (row) => {
    let text = "";
    let numAttributes = 0;

    /**
     * Scans the current row for populated fields.
     * Returns a comma-delimited string of headerName:value of the first two populated fields.
     * E.g. A row with Service ID = SID123, Center Frequency = null, Path ID = PID123, Average Count = 123 
     * would return Service ID: SID123, Path ID = PID123.
     * It skips centerFrequency as it is null, and averageCount as it already has two fields.
     */
    for (let i = 0; i < columnsModel.length; i++) {
      if (row[columnsModel[i].field]) {
        if (text.length > 0) {
          text += ", ";
        }
        text += columnsModel[i].headerName + ": " + row[columnsModel[i].field];

        // only include the first two attributes to avoid crowding the text
        numAttributes += 1;
        if (numAttributes >= 2) {
          return text;
        }
      }
    }
    return text;
  }

  // When the delete button is clicked, the row is deleted
  const handleDeleteClick = (pk) => () => {
    // open the confirmation dialogue
    const row = apiRef.current.getRow(pk);
    handleDeletePromptOpen(identifyRow(row));

    // if the user confirms, delete the row
    trackDeleteConfirmation().then((confirmed) => {
      if (confirmed) {
        processDelete(pk);
      }
    })
  };

  // When the cancel button is clicked, the row is reverted to the original state
  const handleCancelClick = (pk) => () => {
    setRowModesModel({
      ...rowModesModel,
      [pk]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    /**
     * Remove the new row if the user cancels a new row
     * which hasn't been edited yet. The primary key will be -1.
     */
    if (pk === -1) {
      apiRef.current.updateRows([{ pk: pk, _action: 'delete' }])
    }
  };

  /**
   * DataGrid requires an identifier for each row.
   * The id of our rows are called pk, so we
   * must manually specify this.
   * @param {*} row 
   * @returns unique identifier of the row
   */
  const getRowId = (row) => {
      return row.pk;
  }

  /**
   * Get the index of a row in the sorted rows.
   * @param {GridRow} row 
   * @returns the index of the row in the sorted rows
   */
  const getRowIndex = (row) => {
    // get the sorted rows
    let sortedRows = apiRef.current.getSortedRows();
    /**
     * Filter out rows that do not have a primary key.
     * These are rows that represent groups.
     * We don't want to consider these in the index calculation.
     */
    sortedRows = sortedRows.filter(row => row.pk !== undefined);

    /**
     * If the pk is -1, this is a new row that has not been saved yet.
     * It will already be in the sorted rows list. We don't need to add it.
     * Otherwise, it is a row that has been successfully added by our API,
     * but not yet by the Data Grid API. This will naturally happen, but,
     * unfortunately, we need to know where the row will be in the sorted list
     * before that happens. So, we just add it. This won't affect the grid.
     */ 
    if (row.pk !== -1) {
      sortedRows.push(row);
      
      const sort = apiRef.current.getSortModel()?.[0]?.sort;

      // sort the rows by the sort key
      sortedRows = sortedRows.sort((a, b) => {
        // sort according to direction
        if (a[sortKey] < b[sortKey]) return sort === 'asc' ? -1 : 1;
        if (a[sortKey] > b[sortKey]) return sort === 'asc' ? 1: -1;
        return 0;
      });
    }

    // return the index of the row in the sorted rows
    return sortedRows.findIndex((item) => item.pk === row.pk);
  }

  /**
   * Brings a row to view by navigating to the correct page.
   * Works with rows that are not currently in state, as long
   * as they will be in state after the page is navigated to.
   * @param {GridRow} row 
   */
  const bringRowToView = (row) => {
    // get the current page size
    const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
    // get the destined position (index) of the row
    const destinedPosition = getRowIndex(row);
    // compute the destination page
    const destinationPage = Math.floor(destinedPosition / pageSize);
    // navigate to the destination page to bring the row to view
    apiRef.current.setPage(destinationPage);
  }

  /**
   * Triggered when editing a row is finished.
   * This includes adding a new row, as that is technically
   * carried out by adding a row then editing it.
   * Used to add/update a data entry.
   * @param {*} updatedRow The updated row data
   * @param {*} originalRow The original row data
   * @returns The row that was saved, either the updated or original
   */
  const processRowUpdate = async (updatedRow, originalRow) => {
    // trigger loading animation while processing
    setLoading(true);

    // client-side validation
    const errorMessage = validation(updatedRow);
    if (errorMessage) {
      setRowModesModel({
        ...rowModesModel,
        [updatedRow.pk]: { mode: GridRowModes.Edit, ignoreModifications: true },
      });
      return Promise.reject(new Error(errorMessage));
    }

    // add or update the row

    /**
     * Second condition is required as isNew may flag a row as new when the user
     * remains on the page after successfully adding a new row. At this point,
     * it is not new as it has been added to the database. Luckily, the pk should
     * be populated in that case. I believe this is an internal bug as of mui-data-grid v6.18.4.
     * Future updates may render the second condition unnecessary. In that case, it should be removed.
     */
    const res = updatedRow.isNew && updatedRow.pk === -1 ?
                await fetchData(baseUrl, {
                    method: 'POST',
                    body: JSON.stringify(updatedRow),
                    headers: {
                        "Content-Type": "application/json",
                    }
                }, true)
                : await fetchData(`${baseUrl}/${updatedRow.pk}`, { 
                    method: 'PUT', 
                    body: JSON.stringify(updatedRow),
                    headers: {
                        "Content-Type": "application/json",
                    }
                }, true);
    
    // return the updated row if the server accepted the request
    if (res.status === StatusCodes.NO_CONTENT ||
        res.status === StatusCodes.CREATED || 
        res.status === StatusCodes.OK) {    
        if ( updatedRow.isNew && updatedRow.pk === -1 ) {
          /**
           * Update the primary key. Here, we've added a new stand-in
           * row with a random pk. To keep the grid in sync with the
           * backend, we must update the primary key of the updated row.
           */
          const validatedRowData = await res.json();
          updatedRow.pk = validatedRowData.pk;
          // The original row should be deleted.
          apiRef.current.updateRows([{ pk: originalRow.pk, _action: 'delete' }])
          
          // bring added entry to view
          bringRowToView(updatedRow);
          setSuccessMessage("Successfully added entry!")
        }

        else {
          setSuccessMessage("Successfully updated entry!");
        }

        // processing is done, so we can stop the loading animation
        setLoading(false);
        return updatedRow;
    }
    
    // server-side validation
    else {
      setRowModesModel({
        ...rowModesModel,
        [updatedRow.pk]: { mode: GridRowModes.Edit, ignoreModifications: true },
      });
      
      const errorText = await res.text();
      return Promise.reject(new Error(`${res.statusText} (${res.status}): ${errorText}`));
    }
  }

  // Responds to failed adds/edits
  const onProcessRowUpdateError = (error) => {
    // Display an error message if the row update fails
    setErrorMessage(error.message);

    // processing is done, so we can stop the loading animation
    setLoading(false);
  }

  /**
   * Respond to the add button being clicked.
   */
  const handleAddClick = () => {
    /** 
     * Used to satisfy DataGrid API.
     * Will not be inserted into the db.
     */
    const newPk = -1;

    /**
     * Create a new row with the same columns as the grid.
     * These should be empty values, aside from the primary key.
     */
    const emptyColumns = columnsModel.reduce((acc, column) => {
      /**
       * If the grid is using grouped rows, then set the default
       * value of the grouped row to null. The grid checks if
       * a row does not have a value for the group (i.e. null). If not,
       * it will display it inline. This is what we want for a grouped
       * row. Otherwise, giving it a value of "" will cause the grid
       * to create a new group for the row, which is not what we want.
       * See https://mui.com/x/react-data-grid/row-grouping/#rows-with-missing-groups
       * We want a value of "" otherwise because this avoids errors with
       * singleSelect column types, which require a value.
       * See https://mui.com/x/react-data-grid/column-definition/#column-types
       */
      acc[column.field] = column.field === fieldToGroup ? null : "";

      /** 
       * Map new row values according to caller. This is how
       * we can set default values for new rows.
       * The user defines an array of objects with the field
       * and value properties. We check to see if any of our rows
       * match any of the fields. If so, we set the value of the
       * row to the value of the object.
       */
      if (newRowMap?.some((row) => row.field === column.field)) {
        acc[column.field] = newRowMap.find((row) => row.field === column.field).value;
      }
      
      return acc;
    }, {});
    const newRow = { pk: newPk, ...emptyColumns, isNew: true };
    
    // Update rows and bring row to view
    apiRef.current.updateRows([newRow]);
    
    /**
     * The API is not aware of the new row yet, so we must increment the row count
     * This is vital before calling bringRowToView, as if a new page was created
     * due to the new row, then the view would not be brought to the new page.
     */
    apiRef.current.setRowCount(apiRef.current.state.pagination.rowCount + 1);
    bringRowToView(newRow);

    // set the new row to edit mode
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newPk]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
    }));
  };

  return (
    <Fragment>
      { 
        addEnabled ?
          <Stack 
            direction="row"
            sx={{
              marginTop: '1rem'
            }}
            >
            <Button 
              color="primary" 
              startIcon={<AddIcon />} 
              onClick={handleAddClick}
              disabled={loading}
            >
              Add record
            </Button>
          </Stack>
          :
          null
      }
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        editMode="row"
        getRowId={getRowId}
        loading={loading}
        rowModesModel={rowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        /**
         * Needs to be enabled.
         * see https://mui.com/x/react-data-grid/pagination/#pagination-model
         */        
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
          sorting: {
            // sort by grouped column if grouping is enabled
            sortModel: fieldToGroup ?
              [{ 
                // see https://mui.com/x/react-data-grid/row-grouping/#full-example
                // taken from example directly
                field: '__row_group_by_columns_group__', sort: 'asc' 
              }]
              :
              [{ 
                field: sortKey, sort: 'asc' 
              }]
          },
          pinnedColumns: pinColumns &&
            {
              left: [columns ? columns[0].field : null],
              right: [actionsEnabled ? "actions" : null]
            },
          rowGrouping: {
            model: [fieldToGroup]
          }
        }}
        groupingColDef={{
          flex: columns.find((col) => col.field === fieldToGroup)?.flex,
          headerName: "Group"
        }}
        localeText={{
          noRowsLabel: noRowsText
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        data-testid={testId}
        className={className}
        /**
         * Disable virtualisation if running E2E tests. This is because
         * headless browsers are used, which are not compatible with virtualisation.
         * See https://mui.com/x/react-data-grid/virtualization/#disable-virtualization
         * Note: Using a headless browser check is not sufficient. For example,
         * navigator.webdriver works on Chromium, Edge and Webkit, but not Firefox.
         */
        disableVirtualization={process.env.REACT_APP_E2E_TEST === 'true'} 
      />
      <DeletePrompt
        open={deletePromptOpen}
        identifierText={deleteIdentifierText}
        handleClose={handleDeletePromptClose}
      />
    </Fragment>
  );
}