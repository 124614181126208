import { useState, useCallback } from 'react';
import { Endpoints } from '../Constants/Endpoints';

// Hook to use site IDs, for convenience
export const useSiteIds = () => {
    const [siteIds, setSiteIds] = useState([]); // The list of site IDs
    const [selectedSiteId, setSelectedSiteId] = useState(''); // The selected site ID

    const handleSiteIdChange = useCallback((event) => {
        setSelectedSiteId(event.target.value);
    }, [setSelectedSiteId]);

    // Get all site IDs - runs on render
    const getAllSiteIds = useCallback((fetchData, setErrorMessage) => {
        fetchData(Endpoints.GetAllSiteIds)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            // set the site IDs
            setSiteIds(data);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
    }, [setSiteIds]);

    return {
        siteIds: siteIds,
        selectedSiteId: selectedSiteId,
        handleSiteIdChange: handleSiteIdChange,
        getAllSiteIds: getAllSiteIds,
    };
};