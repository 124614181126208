import { createContext } from 'react';

/**
 * The site ID context. 
 * This allows the site ID to be passed deeply and clearly through the component tree.
 * See https://react.dev/learn/passing-data-deeply-with-context
 * The steps to using it are:
 * 1. Create the context (this file)
 *      https://react.dev/learn/passing-data-deeply-with-context#step-1-create-the-context
 * 2. Use the context (Call useContext() in the consuming component, e.g. LiveData.js)
 *      https://react.dev/learn/passing-data-deeply-with-context#step-2-use-the-context
 * 3. Provide the context in the parent component (PageLayout.js)
 *      https://react.dev/learn/passing-data-deeply-with-context#step-3-provide-the-context
 */
export const SiteIdContext = createContext('');