import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// See https://mui.com/material-ui/react-alert/
export const Severities = {
    Error: "error",
    Warning: "warning",
    Info: "info",
    Success: "success"
};

/**
 * A snackbar that displays an alert message. 
 * @returns 
 */
export default function AlertSnackbar({open, setOpen, message, autoHideDuration, severity, alertDisplay}) {
    const handleCloseSnackbar = () => {
        setOpen(false);
    }
    
    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={
                // If the message is long, display it for longer
                typeof(message) === "string" && message.length > 100 ?
                    autoHideDuration * 3
                    :
                    autoHideDuration
            }
        >
            <Alert 
                severity={severity} 
                onClose={handleCloseSnackbar}
                sx={{
                    maxWidth: '500px',
                    maxHeight: '200px',
                    textAlign: 'center'
                }}
            >
                <AlertTitle
                    sx={{
                        textAlign: 'center'
                    }}
                    severity={severity}
                >
                    {alertDisplay}
                </AlertTitle>
                {
                    /**
                     * Do not attempt to pass an object as a child. This will
                     * cause a crash. Only print a string. If not, print a generic error.
                     */
                    typeof(message) === "string" ? message : "Unknown error occurred."
                }
            </Alert>
        </Snackbar>
    )
}