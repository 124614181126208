/**
 * @fileOverview ScheduledTests Model
 * @module ScheduledTests
 * @exports ScheduledTests
 * @description Contains Scheduled Tests format.
 * See https://mui.com/x/api/data-grid/grid-col-def/ for column definition references
 */
export const ScheduledTests = [
    // Only the Service ID is required
    {
        // the data to read
        field: "serviceId",
        // the column header text
        headerName: "Service ID",
        // flex is the relative space this column will take up
        flex: 1,
        editable: "true"
    },
    {
        field: 'testName',
        headerName: 'Test Name',
        flex: 1,
        editable: "true"
    },
    {
        field: 'units',
        headerName: 'Units',
        flex: 0.5,
        editable: "true"
    },
    {
        field: 'lowerLimit',
        headerName: 'Lower Limit',
        flex: 0.5,
        editable: "true"
    },    
    {
        field: 'upperLimit',
        headerName: 'Upper Limit',
        flex: 0.5,
        editable: "true"
    }
];

/**
 * Validation criteria for adding/editing rows to the Scheduled Tests grid
 * @param {The row object to validate} row 
 * @returns an error message if the row is invalid, or an empty string if the row is valid
 */
export const scheduledTestsFieldValidation = (row) => {
    if (!row.serviceId || row.serviceId === "") {
        return "Service ID is required";
    } 

    if (!row.testName || row.testName === "") {
        return "Test name is required";
    }

    if (!row.units || row.units === "") {
        return "Units are required";
    }

    if (!row.lowerLimit || isNaN(row.lowerLimit)) {
        return "Lower limit must be a number";
    }

    if (!row.upperLimit || isNaN(row.upperLimit)) {
        return "Upper limit must be a number";
    }

    if (row.lowerLimit >= row.upperLimit) {
        return "Lower limit must be less than upper limit";
    }

    return "";
};