import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import '../../../../Styles/LiveData/Panels/Selections/measurement-controls.css';

// A generic button related to measurements
const MeasurementsButton = ({
    title,
    tooltip,
    color,
    onClick,
    disabled,
    endIcon,
    sx={}
}) => {
    return (
        <Button
            className="measurements-button"
            variant="outlined"
            color={color}
            size="large"
            onClick={onClick}
            disabled={disabled}
            endIcon={endIcon}
            title={tooltip}
            sx={sx}
        >
            {title}
        </Button>
    )
}

// Allows the user to override local aparameters
const OverrideParametersControls = ({
    setOverrideParameters
}) => {
    return (
        <Box>
            <FormGroup
                sx={{
                    flexDirection: 'row'
                }}
            >
                <FormControlLabel
                    control={<Checkbox/>} 
                    label="Override Local Parameters"
                    onChange={(event) => {
                        setOverrideParameters(event.target.checked);
                    }}
                />
            </FormGroup>
        </Box>
    )
}

// Contains controls for continuous sweep
const ContinuousSweepControls = ({
    continuousSweep,
    setContinuousSweep,
    measurementResultsProcessing
}) => {
    return (
        <Box>
            <FormGroup
                sx={{
                    flexDirection: 'row'
                }}
            >
                <FormControlLabel 
                    control={<Checkbox checked={continuousSweep?.on}/>} 
                    label="Continuous"
                    disabled={measurementResultsProcessing}
                    onChange={(event) => {
                        setContinuousSweep({
                            ...continuousSweep,
                            on: event.target.checked
                        })
                    }}
                />
                <FormControlLabel 
                    disabled={!continuousSweep.on || measurementResultsProcessing}
                    control={<Checkbox/>} 
                    label="Max Hold"
                    onChange={(event) => {
                        setContinuousSweep({
                            ...continuousSweep,
                            maxHold: event.target.checked
                        })
                    }}
                />
                <FormControlLabel 
                    disabled={!continuousSweep.on || measurementResultsProcessing}
                    control={<Checkbox/>} 
                    label="Min Hold"
                    onChange={(event) => {
                        setContinuousSweep({
                            ...continuousSweep,
                            minHold: event.target.checked
                        })
                    }}
                />
            </FormGroup>
        </Box>
    )
}

// Contains the measurement controls to start measurements
export default function MeasurementControls ({
    startMeasurements,
    stopMeasurements,
    updatingQueue,
    queueLength,
    updateQueueLength,
    selectedSiteId,
    continuousSweep,
    setContinuousSweep,
    measurementResultsProcessing,
    setOverrideParameters,
    continuousMeasurementProcessing
}) {
    return (
        <Box
            sx={{
                marginTop: '1rem'
            }}
        >
            <Box>
                <OverrideParametersControls
                    setOverrideParameters={setOverrideParameters}
                />
                <ContinuousSweepControls
                    continuousSweep={continuousSweep}
                    setContinuousSweep={setContinuousSweep}
                    measurementResultsProcessing={measurementResultsProcessing}
                />
            </Box>            
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {
                    continuousMeasurementProcessing ? 
                        <MeasurementsButton
                            title={"Stop Measurements"}
                            color={"error"}
                            onClick={stopMeasurements}
                            disabled={queueLength !== 0 || updatingQueue}
                            endIcon={<StopIcon/>}
                            tooltip={"Stop measurements"}
                            sx={{
                                marginRight: '0.5rem'
                            }}
                        />
                        :
                        <MeasurementsButton
                            title={"Start Measurements"}
                            color={"primary"}
                            onClick={startMeasurements}
                            disabled={queueLength !== 0 || updatingQueue || measurementResultsProcessing}
                            endIcon={<SendIcon/>}
                            tooltip={"Start measurements"}
                            sx={{
                                marginRight: '0.5rem'
                            }}
                        />
                }
                <MeasurementsButton
                    title={
                        "Queue Length:" +
                        (updatingQueue || queueLength === -1 ?
                            selectedSiteId === '' ?
                                " Unknown"
                                :
                                " Loading..."
                            :
                            " " + queueLength)
                    }
                    color={"primary"}
                    onClick={updateQueueLength}
                    disabled={selectedSiteId === '' || updatingQueue}
                    endIcon={<RefreshIcon/>}
                    tooltip={"Refresh queue length"}
                />
            </Box>
        </Box>
    )
}
