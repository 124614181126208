import GenericCMSSelection from '../../../../Components/GenericCMSSelection';
import dayjs from 'dayjs';

/**
 * Contains the selections for a generic selection in the CMS database. 
 * This is always based on a site ID and service ID.
 */
export default function SpectraSelection({
    selectedSiteId,
    selectedServiceId,
    selectedSpectra,
    setSelectedSpectra,
    spectraSelections,
    spectraSelectionsLoaded
}) {
    return (
        <GenericCMSSelection
            selectedSiteId={selectedSiteId}
            selectedServiceId={selectedServiceId}
            selectedValue={selectedSpectra}
            setSelectedValue={setSelectedSpectra}
            valueTypes={spectraSelections}
            valueTypesLoaded={spectraSelectionsLoaded}
            label="Spectra"
            renderValue={(value) => {
                if (value === '') {
                    return '';
                }
                
                // format the date to be readable
                return dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
            }
        />
    )
}