import { useState, useRef, useCallback } from 'react';

export const useDeletePrompt = () => {
    const [open, setOpen] = useState(false);
    const [identifierText, setIdentifierText] = useState("");
    // Not used for rendering, can useRef instead. See https://react.dev/reference/react/useRef#
    const resolveConfirmation = useRef(null);

    /**
     * Creates a Promise for the caller to track the confirmation of the delete prompt.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean value.
     */
    const trackConfirmation = () => new Promise((resolve, _reject) => {
        // set the resolve function of the Promise to reference later
        resolveConfirmation.current = resolve;
    });

    const handleOpen = useCallback((text) => {
        setIdentifierText(text);
        setOpen(true);
    }, []);

    const handleClose = useCallback((confirmed) => {
        setOpen(false);
        // resolve the Promise with the confirmation value
        resolveConfirmation.current(confirmed);
    }, []);

    return {
        deletePromptOpen: open,
        deleteIdentifierText: identifierText,
        trackDeleteConfirmation: trackConfirmation,
        handleDeletePromptOpen: handleOpen,
        handleDeletePromptClose: handleClose
    };

};