import { useState, useCallback } from "react";

export const useUpload = () => {
    // States
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    // Attempts to upload the selected file
    const uploadFile = useCallback((fetchData, endpoint, setParentLoading = () => {}, setSuccessMessage, setErrorMessage) => {
        setUploading(true);
        /**
         * Used to update the parent component, whatever it may be,
         * to show that uploading is in progress.
         */
        setParentLoading(true);

        // POST the file to the server
        fetchData(endpoint, {
            method: "POST",
            body: formData
        })
        .then(res => {
            if (res.ok) {
                setSuccessMessage("File uploaded successfully");
                // uploaded only on success
                setUploaded(true);
            }
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
        // no longer uploading even on fail
        .finally(() => {
            setUploading(false);
            setParentLoading(false);
        })
    }, [formData]);

    return {
        file: file,
        setFile: setFile,
        formData: formData,
        setFormData: setFormData,
        uploading: uploading,
        uploaded: uploaded,
        setUploaded: setUploaded,
        uploadFile: uploadFile
    };
}