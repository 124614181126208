import { Typography } from "@mui/material";
import CenteredContainer from "../Components/CenteredContainer";
import ReturnButton from "../Components/ArrowButton";
import DefaultBackground from "../Components/DefaultBackground";

/**
 * Renders a 404 page when the user tries to access a page that does not exist.
 * @returns 
 */
export default function NotFound() {
    return (
        <DefaultBackground>
            <CenteredContainer>
                <Typography variant="h2" sx={{
                    marginBottom: "2.5rem"
                }}>
                    Oops! This page does not exist
                </Typography>
                <Typography variant="h5">
                    Please check that the URL is correct.
                </Typography>
                <ReturnButton url="/" text="Back home"/>
            </CenteredContainer>  
        </DefaultBackground>
    );
}