import Box from '@mui/material/Box';
import NavigationBar from "./NavigationBar"
import MsalError from '../Pages/MsalError';
import MsalLoading from '../Pages/MsalLoading';
import AlertSnackbar from './AlertSnackbar';
import { useState, useContext } from 'react';
import { useSnackbar } from '../Hooks/useSnackbar';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { NavigationItems } from "../Constants/NavigationItems"
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { SiteIdContext } from '../Contexts/SiteIdContext';
import { AlertSnackbarContext } from "../Contexts/AlertSnackbarContext";
import MuiXLicense from './MuiXLicense';


/**
 * The layout wrapper for all pages in the app.
 * Ensures the user is logged in and renders the
 * navigation bar.
 * @returns 
 */
export default function PageLayout({...props}) {
    const authRequest = {
        ...loginRequest,
    };

    /**
     * Get the context. See https://react.dev/learn/passing-data-deeply-with-context#step-2-use-the-context
     */
    const siteIdContext = useContext(SiteIdContext);
    // Use the snackbar for alerts
    const {
        snackbarOpen, snackbarMessage,
        alertDisplay, alertSeverity,
        setSnackbarOpen, setWarningMessage,
        setErrorMessage, setSuccessMessage } = useSnackbar(); 
    // Since we need the site ID from NavigationBar, we declare the state here and pass the setter to NavigationBar
    const [selectedSiteId, setSelectedSiteId] = useState(siteIdContext);
    // Set the snackbar context
    const alertSnackbarContext = useContext(AlertSnackbarContext);
    alertSnackbarContext.setSuccessMessage = setSuccessMessage;
    alertSnackbarContext.setErrorMessage = setErrorMessage;
    alertSnackbarContext.setWarningMessage = setWarningMessage;

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            loadingComponent={MsalLoading}
            errorComponent={MsalError}
        >
        <Box
            // Lay components out in a column
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // take up the full height of the viewport
                height: '100vh'
            }}
        >
            <AlertSnackbarContext.Provider value={alertSnackbarContext}>
                <Box>
                    <NavigationBar
                        pages={NavigationItems}
                        selectedSiteId={selectedSiteId}
                        setSelectedSiteId={setSelectedSiteId}
                        setErrorMessage={setErrorMessage}
                    />
                </Box>
                <SiteIdContext.Provider value={selectedSiteId}>
                    <Box
                        // Take up the remaining space not occupied by the navigation bar
                        sx={{
                            flexGrow: 1
                        }}
                        /**
                         * The key is used to force a re-render when the site ID changes
                         * see https://react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
                         */
                        key={selectedSiteId}
                    >
                        {props.children}
                        <MuiXLicense />
                    </Box>
                </SiteIdContext.Provider>
                <AlertSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    autoHideDuration={5000}
                    severity={alertSeverity}
                    alertDisplay={alertDisplay}
                />
            </AlertSnackbarContext.Provider>
        </Box>
        </MsalAuthenticationTemplate>
    )
}