import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useSignalR } from '../Hooks/useSignalR';
import { AlertSnackbarContext } from "../Contexts/AlertSnackbarContext";
import '../Styles/application-status.css';

/**
 * Displays the status and information of the local application.
 */
export default function ApplicationStatus({ selectedSiteId }) {
    // Data holder for the info to display
    const [message, setMessage] = useState({ 
        status: "Unknown",
        message: "The application status is unknown.", 
        additionalMessage: "The application status is unknown. Please wait for an update."
    });

    /**
     * Update state based on prop. No need to use an effect.
     * See https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
     */
    const [prevSelectedSiteId, setPrevSelectedSiteId] = useState(selectedSiteId);
    if (selectedSiteId !== prevSelectedSiteId) {
        setMessage({ 
            status: "Unknown",
            message: "The application status is unknown.", 
            additionalMessage: "The application status is unknown. Please wait for an update."
        });
        setPrevSelectedSiteId(selectedSiteId);
    }

    // Whether the dialogue is open
    const [dialogueOpen, setDialogueOpen] = useState(false); 

    // Use SignalR to retrieve data in real-time
    const {
        establishSignalRConnection, establishSocketListener,
        socketMessage
    } = useSignalR();

    // Use the snackbar for alerts
    const { setErrorMessage } = useContext(AlertSnackbarContext);

    /** Helper functions **/

    // Open and close the dialogue
    const handleDialogueOpen = () => {
        setDialogueOpen(true);
    }

    const handleDialogueClose = () => {
        setDialogueOpen(false);
    }

    /** Effects **/

    useEffect(() => {
        // Establish the SignalR connection
        return establishSignalRConnection("Application");
    }, [establishSignalRConnection]);

    useEffect(() => {
        // Listen for messages
        establishSocketListener("ApplicationStatus");
    });

    useEffect(() => {
        const data = JSON.parse(socketMessage);
        // if the data is valid
        if (data && data.hasOwnProperty("siteId") && data.hasOwnProperty("status")) {
            // Update the status if the siteId matches the selected siteId
            if (data.siteId === selectedSiteId )
            {
                setMessage(data);
            }
        }

        // without the required attributes, we have an error
        else if (data) {
            setErrorMessage("Received an invalid application status update");
        }
    }, [socketMessage, selectedSiteId, setErrorMessage])


    return (
        <Box
            className="application-status-container"
            data-testid="application-status"
        >
            <Tooltip 
                title={message.message}
            >
                <IconButton
                    /**
                     * To fill in the background of the icon,
                     * we set the background colour of the button,
                     * which is essentially the container.
                     */
                    sx={{
                        backgroundColor: 
                            message.status !== "Active" ? 
                                'white' 
                                : 
                                'transparent',
                    }}
                    /**
                     * We use this class to reduce the
                     * size of the icon button to roughly
                     * match the size of the icon. This allows
                     * the background colour to "look" like it's
                     * filling in the icon.
                     */
                    className="application-status-button"
                    onClick={handleDialogueOpen}
                >
                    {
                        message.status === "Ready" ? (
                            <CheckCircleIcon
                                fontSize="large"
                                className="ready-status"
                            />
                        ) : message.status === "Active" ? (
                            <CircleIcon
                                fontSize="large"
                                className="active-status"
                            />
                        ) : message.status === "Fault" ? (
                            <ErrorIcon
                                fontSize="large"
                                color="error"
                            />
                        ) : (
                            <HelpIcon
                                fontSize="large"
                                className="unknown-status"
                            />
                        )
                    }
                </IconButton>
            </Tooltip>
            <Dialog
                open={dialogueOpen}
                onClose={handleDialogueClose}
                data-testid="application-status-dialogue"
            >
                <DialogTitle
                    // reduce innate padding
                    sx={{
                        paddingBottom: '10px'
                    }}
                >
                    Application Status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        // assign class based on status
                        variant="subtitle1"
                        className= {
                            message.status === "Ready" ? "ready-text" : 
                            message.status === "Active" ? "active-text" : 
                            message.status === "Fault" ? "fault-text" : 
                            "unknown-text"
                        }
                        color = {
                            message.status === "Fault" ? "error" : null
                        }
                    >
                        {
                            message.message ?? "The application update did not provide a message."
                        }
                    </DialogContentText>
                    <DialogContentText
                        variant="subtitle2"
                    >
                        {
                            message.additionalMessage ?? "The application update did not provide any details."
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogueClose}>
                        Acknowledge
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}