import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import PageLayout from './Components/PageLayout';
import { Outlet } from 'react-router-dom';

export default function App() {
    /**
     * Main view. Wrap everything in the standard layout.
     * The Outlet component is used to render components
     * based on the current route.
     */
    return (
        <PageLayout>
            <Outlet/>
        </PageLayout>
    );
}