import Box from '@mui/material/Box';
import TimelineIcon from '@mui/icons-material/Timeline';
import { LineChart } from '@mui/x-charts/LineChart';
import CardDisplay from '../../../Components/CardDisplay';
import dayjs from 'dayjs';
import '../../../Styles/panel.css';

// Displays time-series data againts a single y-axis
const SingleLineChart = ({
    xAxisKey,
    yAxisKey,
    upperLimitKey,
    lowerLimitKey,
    timeSeriesData
}) => {
    const showMark = timeSeriesData?.length > 1 ? false : true;
    return (
        <LineChart
            xAxis={[
                { 
                    dataKey: xAxisKey,
                    // when displaying data, convert from unix timestamp to date/time
                    valueFormatter : (value) => dayjs.unix(value).format('DD/MM/YYYY HH:mm:ss'),
                    scaleType: 'time',
                }
            ]}
            series={[
                {
                    dataKey: yAxisKey,
                    connectNulls: true,
                    curve: 'linear',
                    label: 'Value',
                    // MUI blue
                    color: 'rgb(46, 150, 255)',
                    showMark: showMark
                },
                upperLimitKey ?
                    {
                        dataKey: 'upperLimit',
                        label: 'Upper Limit',
                        // warm orange
                        color: 'rgb(250, 128, 114)',
                        curve: 'linear',
                        showMark: showMark
                    } 
                    : 
                    null,
                lowerLimitKey ?                                
                    {
                        dataKey: 'lowerLimit',
                        label: 'Lower Limit',
                        // teal green
                        color: 'rgb(2, 178, 175)',
                        curve: 'linear',
                        showMark: showMark
                    }:
                    null
            ]}
            dataset={timeSeriesData}
            margin={{ left: 75 }}
        />
    )
}

// Displays time-series data against multiple axes
const MultiLineChart = ({
    xAxisKey,
    multipleLineAxes,
    multipleLineData
}) => {
    const showMark = multipleLineData?.length > 1 ? false : true
    return (
        <LineChart
            xAxis={[
                { 
                    dataKey: xAxisKey,
                    // when displaying data, convert from unix timestamp to date/time
                    valueFormatter : (value) => dayjs.unix(value).format('DD/MM/YYYY HH:mm:ss'),
                    scaleType: 'time',
                }
            ]}
            yAxis={[
                {
                    id: 'leftAxisId'
                },
                {
                    id: 'rightAxisId'
                }
            ]}
            series={[
                // left y-axis data
                {
                    dataKey: [...multipleLineAxes][0],
                    connectNulls: true,
                    curve: 'linear',
                    label: [...multipleLineAxes][0],
                    yAxisKey: 'leftAxisId',
                    showMark: showMark
                },
                // right y-axis data
                {
                    dataKey: [...multipleLineAxes][1],
                    connectNulls: true,
                    curve: 'linear',
                    label: [...multipleLineAxes][1],
                    yAxisKey: 'rightAxisId',
                    showMark: showMark
                }
            ]}
            dataset={multipleLineData}
            rightAxis="rightAxisId"
            margin={{ left: 75 }}
        />
    )
}

/**
 * Contains a basic chart for time series data.
 * Intended for use with the Measurement Time Series page.
 */
export default function TimeSeriesChartPanel({
    timeSeriesData,
    dataLoading,
    dataLoaded,
    xAxisKey,
    yAxisKey,
    upperLimitKey,
    lowerLimitKey,
}
) {
    // check if there are multiple lines to display
    const multipleLineAxes = new Set(timeSeriesData.map(item => item.name));
    const multipleLines = multipleLineAxes.size > 1;

    /**
     * Format data for multiple line chart.
     * We expect the data to be an array of objects with the following structure:
     * {
     *     yAxisKey: 'value',
     *     name: 'measurement type 1',
     *     realValue: 123.45
     * }
     * We want to transform this into an array of objects with the following structure:
     * {
     *    yAxisKey: 'value',
     *   'measurement type 1': 123.45,
     *   'measurement type 2': 678.90
     * }
     */
    const multipleLineData = Object.values(timeSeriesData.reduce((acc, item) => {
        acc[item[xAxisKey]] = acc[item[xAxisKey]] || {[xAxisKey]: item[xAxisKey]};
        acc[item[xAxisKey]][item.name] = item.realValue;
        return acc;
    }, {}));

    return (
        <Box
            className='content-panel'          
        >  
            {
                !dataLoaded || dataLoading ?
                    <CardDisplay
                        text={dataLoading ? "Loading..." : "Select a measurement!"}
                        loading={dataLoading}
                        Icon={TimelineIcon}
                    />
                    :
                    multipleLines ?
                        <MultiLineChart
                            xAxisKey={xAxisKey}
                            multipleLineAxes={multipleLineAxes}
                            multipleLineData={multipleLineData}
                        />
                        :
                        <SingleLineChart
                            xAxisKey={xAxisKey}
                            yAxisKey={yAxisKey}
                            upperLimitKey={upperLimitKey}
                            lowerLimitKey={lowerLimitKey}
                            timeSeriesData={timeSeriesData}
                        />
            }
        </Box>
    )
}