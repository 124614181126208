import React from 'react';
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";

/**
 * An arrow with a button facing backwards or forward, depending
 * on the value of the back prop.
 * @returns 
 */
export default function ArrowButton({ url, text, marginTop = "2.5rem", back = true }) {
    const navigate = useNavigate();

    return (
        <Button 
            variant="outlined" 
            sx={{
                alignItems: "center",
                marginTop: marginTop,
                marginBottom: "1rem",
            }}
            onClick={() => {
                navigate(url);
        }}
        >
            { 
                back ? (
                    <ArrowBackIcon
                    sx={{
                        marginRight: "0.5rem",
                    }}/>
                ) : 
                (
                    <ArrowForwardIcon
                    sx={{
                        marginRight: "0.5rem",
                    }}/>
                )
            }
            <Typography variant="subtitle2">{text}</Typography>
        </Button>
    )
}