/**
 * @fileOverview MeasurementParametersWide Model
 * @module MeasurementParametersWide
 * @exports MeasurementParametersWide
 * @description Contains the MeasurementParameters model in wide format.
 * This means that instead of key-value pairs, each column is a key, and each row is a value.
 * These columns will be added dynamically.
 * See https://mui.com/x/api/data-grid/grid-col-def/
 */
export const MeasurementParametersWide = [
    // Only the Service ID is required
    {
        // the data to read
        field: "serviceId",
        // the column header text
        headerName: "Service ID",
        // flex is the relative space this column will take up
        width: 300,
        editable: "true"
    },
    {
        field: "pathId",
        headerName: "Path ID",
        width: 200
    },
    // Currently-accepted Measurement Parameters. Can be dynamic in the future.
    {
        field: "centerFrequencyValue",
        headerName: "Center Frequency (MHz)",
        width: 200,
        editable: "true"
    },
    {
        field: "spanFrequencyValue",
        headerName: "Span Frequency (MHz)",
        width: 200,
        editable: "true"
    },
    {
        field: "resolutionBandwidthValue",
        headerName: "Resolution Bandwidth (kHz)",
        width: 200,
        editable: "true"
    },
    {
        field: "videoBandwidthValue",
        headerName: "Video Bandwidth (Hz)",
        width: 175,
        editable: "true"
    },
    {
        field: "averageCountValue",
        headerName: "Average Count",
        width: 150,
        editable: "true"
    },
    {
        field: "referenceLevelValue",
        headerName: "Reference Level (dBm)",
        width: 200,
        editable: "true"
    },
    {
        field: "scalePerDivisionValue",
        headerName: "Scale Per Division (db/Div)",
        width: 200,
        editable: "true"
    },
    {
        field: "markerOffsetFrequencyValue",
        headerName: "Marker Offset Frequency (MHz)",
        width: 250,
        editable: "true"
    },
    {
        field: "occupiedBandwidthTxValue",
        headerName: "Occupied Bandwidth Tx (MHz)",
        width: 250,
        editable: "true"
    },
    {
        field: "eirpPowerOffsetValue",
        headerName: "EIRP Power Offset (dB)",
        width: 200,
        editable: "true"
    },
    {
        field: "channelPowerFrequencySpanValue",
        headerName: "Channel Power Frequency Span (MHz)",
        width: 300,
        editable: "true"
    }
]

/**
 * Validation criteria for adding/editing rows to the Measurement Parameters grid
 * @param {The row object to validate} row 
 * @returns an error message if the row is invalid, or an empty string if the row is valid
 */
export const measurementParametersFieldValidation = (row) => {
    if (!row.serviceId || row.serviceId === "") {
        return "Service ID is required";
    }

    if (!row.serviceId.includes("RX") && !row.serviceId.includes("TX")) {
        return "Service ID must contain RX or TX";
    }

    if (!row.pathId || row.pathId === "") {
        return "Path ID is required";
    }

    if (isNaN(row.centerFrequencyValue)) {
        return "Center Frequency must be a number";
    }

    if (isNaN(row.spanFrequencyValue)) {
        return "Span Frequency must be a number";
    }

    if (isNaN(row.resolutionBandwidthValue)) {
        return "Resolution Bandwidth must be a number";
    }

    if (isNaN(row.videoBandwidthValue)) {
        return "Video Bandwidth must be a number";
    }

    if (isNaN(row.averageCountValue)) {
        return "Average Count must be a number";
    }

    if (isNaN(row.referenceLevelValue)) {
        return "Reference Level must be a number";
    }

    if (isNaN(row.scalePerDivisionValue)) {
        return "Scale Per Division must be a number";
    }

    if (isNaN(row.markerOffsetFrequencyValue)) {
        return "Marker Offset Frequency must be a number";
    }

    if (isNaN(row.occupiedBandwidthTxValue)) {
        return "Occupied Bandwidth Tx must be a number";
    }

    if (isNaN(row.eirpPowerOffsetValue)) {
        return "EIRP Power Offset must be a number";
    }

    if (isNaN(row.channelPowerFrequencySpanValue)) {
        return "Channel Power Frequency Span must be a number";
    }
    
    return "";
};