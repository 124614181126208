import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import '../Styles/selections.css';


// A selection box for the service ID
const ServiceIdSelection = ({
    selectedSiteId,
    serviceIds,
    selectedServiceId,
    handleServiceIdChange
}) => {
    return (
        <FormControl
            className='generic-selection'
        >
            <Autocomplete
                // a value of '' will be compared to existing options and complain if it doesn't match
                value={selectedServiceId === '' ? null : selectedServiceId}
                onChange={handleServiceIdChange}
                loading={selectedSiteId !== '' && serviceIds.length === 0}
                // disabled while loading
                disabled={selectedSiteId !== '' && serviceIds.length === 0}
                options={
                    selectedSiteId === null || selectedSiteId === '' ?
                        [
                            'Please select a Site ID'
                        ]
                        : 
                        (
                            serviceIds.map((serviceId) => {
                                return serviceId.value
                            })
                        )
                }
                getOptionDisabled={(option) =>
                    // this option is always disabled
                    option === 'Please select a Site ID' ? true : false
                }
                renderInput={
                    (params) => 
                        <TextField 
                            {...params} 
                            label="Service ID"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <Fragment>
                                    {selectedSiteId !== '' && serviceIds.length === 0 ? <CircularProgress size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                                ),
                            }}
                        />
                }
            />
        </FormControl>
    )
}

// Contains the selections for service IDs
export default function ServiceSelections ({
    selectedSiteId,
    serviceIds,
    selectedServiceId,
    handleServiceIdChange
}) {
    return (
        <FormGroup>
                <Typography 
                    sx={{
                        width: '100%'
                    }} 
                    noWrap={true}
                    variant="h4"
                >
                    Selections
                </Typography>
                <ServiceIdSelection
                    selectedSiteId={selectedSiteId}
                    serviceIds={serviceIds}
                    selectedServiceId={selectedServiceId}
                    handleServiceIdChange={handleServiceIdChange}
                />
        </FormGroup>
    )
}