import { Endpoints } from '../../Constants/Endpoints';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwapMeasurementsDisplay from '../../Components/SwapMeasurementsDisplay';
import Upload from '../../Components/Upload';
import '../../Styles/ServiceConfiguration/service-configuration.css';

export default function ServiceConfigurationHeader({
    measurementParametersDisplayed,
    setMeasurementParametersDisplayed,
    fetchData,
    setSuccessMessage,
    setErrorMessage,
    uploadDisabled,
    setLoading
}) {
    return (
        <Box
            className='service-configuration-header-container'
        >
            <Typography 
                variant="h4"
                className='service-configuration-title-text'
            >
                {measurementParametersDisplayed ? "Measurement Parameters" : "Scheduled Tests"}
            </Typography>
            <SwapMeasurementsDisplay
                title={"Swap to " + (measurementParametersDisplayed ? "Scheduled Tests" : "Measurement Parameters")}
                placement="right"
                swapMeasurementsDisplay={() => { 
                    setMeasurementParametersDisplayed(!measurementParametersDisplayed)
                }}
            />
            <Upload
                fetchData={fetchData}
                endpoint={Endpoints.UploadApplicationSettings}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                disabled={uploadDisabled}
                setParentLoading={setLoading}
            />
        </Box>
    )
}