/**
 * @file LiveData.js
 * @desc The live data page. Contains the live results panel and the selections panel.
 */

import { useState, useCallback, useEffect, useRef, useContext, Fragment } from 'react';
import Box from '@mui/material/Box';
import LiveResultsPanel from './Panels/LiveResultsPanel.js';
import LiveDataSelectionsPanel from './Panels/Selections/LiveDataSelectionsPanel.js';
import { useFetchWithMsal } from '../../Hooks/useFetchWithMsal.js';
import { useServiceIds } from '../../Hooks/useServiceIds.js';
import { useAccount } from '@azure/msal-react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Endpoints } from '../../Constants/Endpoints.js';
import { SiteIdContext } from '../../Contexts/SiteIdContext.js';
import { AlertSnackbarContext } from '../../Contexts/AlertSnackbarContext.js';
import '../../Styles/panel.css';


export default function LiveData() {
    /** Hooks **/
    const account = useAccount(); // The logged in account
    const { fetchData } = useFetchWithMsal(); // Fetch with MSAL as endpoints are protected
    const {
        // service ID selection functions and data
        serviceIds, selectedServiceId,
        handleServiceIdChange, getServiceIdsForSite
    } = useServiceIds();

    /** Contexts **/

    // The site ID context, essentially provided by the NavigationBar
    const selectedSiteId = useContext(SiteIdContext);
    // The snackbar context for displaying alerts
    const { setErrorMessage, setSuccessMessage, setWarningMessage } = useContext(AlertSnackbarContext); 

    /** States **/

    // References
    const [signalRConnection, setSignalRConnection] = useState(null); // The SignalR connection
    /**
     * Reference to the CMSDataGrid component for measurement parameters. Necessary to retrieve data,
     * as the state is held by the CMSDataGrid, which is a child component.
     */
    const [measurementParametersApiRef, setMeasurementParametersApiRef] = useState(null); 

    // Selections
    const [continuousSweep, setContinuousSweep] = useState({
        // on by default
        on: true,
        maxHold: false,
        minHold: false,
    })
    const [overrideParameters, setOverrideParameters] = useState(false); // Whether or not to override parameters

    // Stages of measurement

    // True after a measurement request has been sent, but before the results have been received
    const [measurementRequestProcessing, setMeasurementRequestProcessing] = useState(false);
    // True after the measurement results have been received
    const [measurementResultsProcessing, setMeasurementResultsProcessing] = useState(false);
    // True when the user has requested a continuous measurement, after the request has been sent
    const [continuousMeasurementProcessing, setContinuousMeasurementProcessing] = useState(false);
    // Ref to above state to access in SignalR scope
    const continuousMeasurementProcessingRef = useRef(continuousMeasurementProcessing);
    // True while the queue length is being updated
    const [updatingQueue, setUpdatingQueue] = useState(false);
    
    // Miscellaneous

    // The length of the live data queue. Used to allow/disallow starting measurements.
    const [queueLength, setQueueLength] = useState(-1);
    // Whether the used parameters grid is displayed. If false, the measurement parameters grid is displayed.
    const [usedParametersDisplayed, setUsedParametersDisplayed] = useState(false);
    // The measurement parameters data, i.e., what parameters could be used to produce "results"
    const [measurementParameters, setMeasurementParameters] = useState([]);
    // The used parameters data, i.e., what parameters were used to produce "results"
    const [usedParameters, setUsedParameters] = useState([]);
    // The measurement results data, i.e., the "results"
    const [measurementResults, setMeasurementResults] = useState([]);
    // The raw sweep data, i.e., the image to display
    const [sweepData, setSweepData] = useState([]);
    // The previous selectedServiceId
    const [prevSelectedServiceId, setPrevSelectedServiceId] = useState(selectedServiceId);

    // State adjustments

    /**
     * Update the continuous measurement processing ref when the state changes
     * This is necessary because the state is used in the SignalR scope.
     * Otherwise, the state would be stale. That is, it would use the 
     * previous value erroneously. References are used to avoid this.
     */
    continuousMeasurementProcessingRef.current = continuousMeasurementProcessing;
     /**
     * Swap measurement display to measurement parameters when the service ID changes
     * Not necessary to use a useEffect hook. Instead, adjust the state while
     * rendering. State changes triggers re-renders, which then triggers the effect.
     * This way, unnecessary re-renders are avoided.
     * See https://react.dev/learn/you-might-not-need-an-effect#how-to-remove-unnecessary-effects
     * and 
     */
    if (prevSelectedServiceId !== selectedServiceId) {
        setPrevSelectedServiceId(selectedServiceId);
        setUsedParametersDisplayed(false);
    }


    /** Helper functions **/

    // Parse the measurement data for parameters and results
    const parseMeasurementData = useCallback((data) => {
        const usedParameters = data.filter((d) => d.parameterType === 'MeasurementParameter');
        const measurementResults = data.filter((d) => d.parameterType === 'TestResult');
        const sweepData = data.filter((d) => d.parameterType === 'SweepData');

        setUsedParameters(usedParameters);
        setMeasurementResults(measurementResults);
        setSweepData(sweepData);
    }, [setUsedParameters, setMeasurementResults, setSweepData]);

    // Empty the measurement data
    const clearMeasurementData = useCallback(() => {
        setUsedParameters([]);
        setMeasurementResults([]);
        setSweepData([]);
    }, [setUsedParameters, setMeasurementResults, setSweepData]);

    // Construct the measurement parameters object
    const constructMeasurementParameters = (start) => {
        let mp = usedParametersDisplayed ?
            // do not attempt to access the grid ref if it is not in the DOM
            measurementParameters
            :
            measurementParametersApiRef?.current?.getSortedRows().map((row) => {
                return {
                    // only send key, value and units
                    key: row.key,
                    value: row.value,
                    units: row.units
                }
            })

        // If the measurement parameters are being overridden, use the ones in the state
        mp = overrideParameters ? mp : [];

        // Update the measurement parameters with continuous sweep parameters
        mp = start ?
            // if starting, add the StartContinuous sweep parameters
            [
                ...mp, 
                {
                    key: 'StartContinuous',
                    value: continuousSweep.on,
                    units: ''
                },
                {
                    key: 'MaxHold',
                    value: continuousSweep.maxHold,
                    units: ''
                },
                {
                    key: 'MinHold',
                    value: continuousSweep.minHold,
                    units: ''
                }
            ]
            :
            // if stopping, add the StopContinuous key
            [
                {
                    key: 'StopContinuous',
                    value: true,
                    units: ''
                }
            ]

        return mp;
    }

    // Send a measurement request with the given measurement arameters
    const sendMeasurementRequest = (measurementParameters, successCallback) => {
        // Send the measurement request
        fetchData(Endpoints.RequestMeasurements, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            // Pass a body object as per the API spec
            body: JSON.stringify({
                // A GUID of all 0s indicates that this is a live data request
                MeasurementScheduleId: '00000000-0000-0000-0000-000000000000',
                MeasurementRequestedAt: new Date().toISOString(),
                ServiceId: selectedServiceId,
                PathId: selectedSiteId,
                PrtgAlarmCode: '',
                // LiveData trigger type indicates that this is a live data request
                TriggerType: 'LiveData',
                // The requester is the logged in user
                Requester: account.username,
                /** 
                 * The measurement parameters are the ones currently displayed in the grid,
                 * alongside additional continuous sweep parameters.
                 */
                MeasurementParameters: measurementParameters,
            })

        })
        // If the request is successful, call the callback function
        .then((res) => {
            if (res.ok) {
                successCallback();
            }
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
        .finally(() => {
            // The measurement request is no longer processing
            setMeasurementRequestProcessing(false);
        })
    }

    /** Event-driven functions **/

    // Swap measurement display between parameters/results
    const swapMeasurementsDisplay = () => {
        /**
         * Store measurement parameters if grid is coming out of DOM. Otherwise,
         * the grid ref will be invalid.
         */
        if (!usedParametersDisplayed) {
            setMeasurementParameters(measurementParametersApiRef?.current?.getSortedRows().map((row) => {
                return {
                    key: row.key,
                    value: row.value,
                    units: row.units
                }
            }));
        }
        setUsedParametersDisplayed(!usedParametersDisplayed);
    }

    // Update queue length
    const updateQueueLength = useCallback(() => {
        // If the site ID is not selected, don't update the queue length
        if (selectedSiteId === '') {
            return;
        }

        // Set updating to true to indicate that the queue length is being updated
        setUpdatingQueue(true);

        // Fetch the queue length
        fetchData(Endpoints.GetLiveDataQueueLength + `/${selectedSiteId}`)
        .then((res) => res.json())
        .then((queueLength) => {
            setQueueLength(queueLength)
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
        // Set updating to false to indicate that the queue length has been updated
        .finally(() => {
            setUpdatingQueue(false);
        })
    }, [fetchData, selectedSiteId, setQueueLength, setUpdatingQueue, setErrorMessage]);

    // Start measuring
    const startMeasurements = () => {
        // If there are measurements in the queue, don't start new ones
        if (queueLength > 0) {
            setWarningMessage("There are measurements already in the queue. Please wait for them to finish.");
            return;
        }

        // If the site ID or service ID is not selected, don't start measurements
        if (selectedSiteId === '' || selectedServiceId === '') {
            setWarningMessage("Please select a Site ID and Service ID");
            return;
        }

        /**
         * Update stage:
         * 1. The measurement request is now processing.
         * 2. The measurement results are not processing.
         * 3. The queue length is being updated.
         */
        setMeasurementRequestProcessing(true);
        setMeasurementResultsProcessing(false);
        setUpdatingQueue(true);

        // Construct the measurement parameters with StartContinuous parameters
        const measurementParameters = constructMeasurementParameters(true);

        // The function to call if the measurement request is successful
        const successCallback = () => {
            setSuccessMessage("Successfully requested measurements!");

            // update stage of measurement
            if (continuousSweep.on) {
                setContinuousMeasurementProcessing(true);
            }
            setMeasurementResultsProcessing(true);

            // The queue should be updated
            updateQueueLength();
        }

        // Send the request
        sendMeasurementRequest(measurementParameters, successCallback);
    }

    // Stop measuring, only applicable to continuous mode
    const stopMeasurements = () => {
        setMeasurementRequestProcessing(true);
        setMeasurementResultsProcessing(false);
        updateQueueLength();

        // Construct the measurement parameters with only the StopContinuous parameter
        const measurementParameters = constructMeasurementParameters(false);

        // The function to call if the measurement request is successful
        const successCallback = () => {
            setSuccessMessage("Successfully sent stop request!");
            // update stage of measurement
            setContinuousMeasurementProcessing(false);
            // clear the measurement data
            clearMeasurementData();
        }

        // Send the stop request
        sendMeasurementRequest(measurementParameters, successCallback);
    }

    /** Effects **/

    // Get all service IDs for the selected site ID - runs when the selected site ID changes
    useEffect(() => {
        // If the selected site ID is empty, don't fetch service IDs
        if (selectedSiteId === '') {
            return;
        }

        getServiceIdsForSite(fetchData, setErrorMessage, selectedSiteId);
        
        // Update the queue length when the site is known. The queue length depends on the site.
        updateQueueLength();
    }, [selectedSiteId, getServiceIdsForSite, fetchData, setErrorMessage, updateQueueLength]);

    // Get the SignalR connection - runs on render
    useEffect(() => {
        // Create the connection
        const connection = new HubConnectionBuilder()
            /**
             * Here, we take advantage of our proxy in setUpProxy.js. This will direct
             * the request to https://localhost:7219/api/LiveData, which is the signalR
             * endpoint. When running in an App Service, it will direct it correctly to
             * the app url - so we don't need to hard-code it.
             */
            .withUrl('api/LiveData')
            .withAutomaticReconnect()
            .build();
        
        // Start the negotiation
        const negotiation = connection.start();

        // Set the connection for reference when negotiation is complete
        negotiation.then(() => {
            setSignalRConnection(connection);
        })
        .catch((e) => {
            setErrorMessage("Connection to SignalR service failed: " + e.message);
        });

        /**
         * The function returned by useEffect is run before
         * re-runs or when the component is unmounted. In either case,
         * closing the connection is a good idea. Otherwise, the user
         * will open infinite connections when repeatedly navigating to the page
         * using the in-app links.
         */
        return () => {
            /**
             * Close the connection only after negotiation is finished.
             * This is crucial. Attempting to stop it before negotiation
             * will throw an error. The only way to know negotiation is
             * finished is to wait for the promise to resolve.
             */
            negotiation.then(() => {
                connection.stop();
            })
            .catch((e) => {
                setErrorMessage("Failed to close SignalR connection: " + e.message);
            });
        }
        
    }, [setErrorMessage]);


    /** 
     * Listen for measurement results - runs when the connection is established.
     * We separate this from the connection effect because we don't want to establish
     * a new connection any time the dependencies change. The dependencies, such as 
     * updateQueueLength, depend on selections. If the selections change, the dependencies
     * change, and a new connection would be forced. This is bad practice.
     */
    useEffect(() => {
        // Don't run unless the connection has been established
        if (signalRConnection) {
            // Listen to the MeasurementResults target
            signalRConnection.on('MeasurementResults', (message) => {
                // Parse the message
                const data = JSON.parse(message);
                // If the message is well-formed, set the measurement data
                if (data && data.hasOwnProperty('measurementData')) {
                    parseMeasurementData(data.measurementData);
                    /**
                     * Indicate that measurement results are no longer processing.
                     * Does not affect the continuous measurement processing state.
                     */
                    setMeasurementResultsProcessing(false);
                    // Display the used parameters instead of the overwritable parameters
                    setUsedParametersDisplayed(true);
                    // The queue should be updated as it should be empty now
                    updateQueueLength();
                    // Indicate that measurement results were retrieved
                    setSuccessMessage("Successfully received measurement results!");
                }
                else {
                    setErrorMessage("Received poorly formatted measurement results. Please try again.")
                }
            })
            
            // Listen to external clients requesting that the continuous measurements be stopped
            signalRConnection.on('StopContinuousMeasurements', () => {
                // use a reference instead of state to avoid stale state
                if (continuousMeasurementProcessingRef.current) {
                    // clean up
                    updateQueueLength();
                    setMeasurementResultsProcessing(false);
                    setContinuousMeasurementProcessing(false);
                    clearMeasurementData();
                    // inform the user about nature of the request
                    setSuccessMessage("Received external request to stop continuous measurements!");
                }

            })
        }
    }, [
        signalRConnection, setErrorMessage, parseMeasurementData, 
        setMeasurementResultsProcessing, setUsedParametersDisplayed,
        continuousMeasurementProcessingRef, updateQueueLength,
        setContinuousMeasurementProcessing, clearMeasurementData, setSuccessMessage
    ]);

    return (
        <Fragment>
            <Box 
                className="panel-container"
            >
                <LiveDataSelectionsPanel
                    selectedSiteId={selectedSiteId}
                    serviceIds={serviceIds}
                    selectedServiceId={selectedServiceId}
                    handleServiceIdChange={handleServiceIdChange}
                    startMeasurements={startMeasurements}
                    updatingQueue={updatingQueue}
                    queueLength={queueLength}
                    updateQueueLength={updateQueueLength}
                    swapMeasurementsDisplay={swapMeasurementsDisplay}
                    usedParametersDisplayed={usedParametersDisplayed}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    setMeasurementParametersApiRef={setMeasurementParametersApiRef}
                    usedParameters={usedParameters}
                    measurementResults={measurementResults}
                    continuousSweep={continuousSweep}
                    setContinuousSweep={setContinuousSweep}
                    stopMeasurements={stopMeasurements}
                    measurementResultsProcessing={measurementResultsProcessing}
                    setOverrideParameters={setOverrideParameters}
                    overrideParameters={overrideParameters}
                    continuousMeasurementProcessing={continuousMeasurementProcessing}
                />
                <LiveResultsPanel
                    measurementRequestProcessing={measurementRequestProcessing}
                    measurementResultsProcessing={measurementResultsProcessing}
                    selectedSiteId={selectedSiteId}
                    selectedServiceId={selectedServiceId}
                    sweepData={sweepData}
                    continuousMeasurementProcessing={continuousMeasurementProcessing}
                />
            </Box>
        </Fragment>
    )
}