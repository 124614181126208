/**
 * @fileOverview MeasurementSchedule Model
 * @module MeasurementSchedule
 * @exports MeasurementSchedule
 * @description Contains the model to be used with the MUI DataGrid to display Measurement Schedules.
 * This is essentially a description of each column.
 * See https://mui.com/x/api/data-grid/grid-col-def/
 */

import dayjs from "dayjs";

export const MeasurementSchedule = [
    {
        // the data to read
        field: "serviceId",
        // the column header text
        headerName: "Service ID",
        // flex is the relative space this column will take up
        flex: 2
    },
    {
        field: "startDate",
        type:'dateTime',
        /** 
         * valueFormatter defines how the value of the column is formatted
         * We want a js Date object, so we convert it using dayjs
         */
        valueFormatter: (value) => dayjs(value).toDate(),
        headerName: "Start Date",
        flex: 1,
        editable: true,
    },
    {
        field: "endDate",
        type:"dateTime",
        valueFormatter: (value) => {
            // If the value is null, display 'Never'
            if (!value) {
                return 'Never';
            }
            
            return dayjs(value).toDate();
        },
        headerName: "End Date",
        flex: 1,
        editable: true,
    },
    {
        field: "period",
        type: 'singleSelect',
        // The options for the dropdown
        valueOptions: [
            { value: 1, label: '1 minute'},
            { value: 15, label: '15 minutes'},
            { value: 60, label: '1 hour' },
            { value: 1440, label: '1 day'},
            { value: -1, label: 'Never'}
        ],
        headerName: "Period",
        flex: 1,
        editable: true
    },
    {
        field: "requester",
        headerName: "Requester",
        flex: 1,
    },
    {
        field: "lastMeasurementPerformedAt",
        type: "dateTime",
        // as with End Date
        valueFormatter: (value) => {
            if (!value) {
                return 'Never'
            }

            return dayjs(value).toDate()
        },
        headerName: "Last Measurement",
        flex: 1,
    },
    {
        field: "totalMeasurementResultsPerformed",
        headerName: "Measurements Performed",
        flex: 1,
    }
]

/**
 * Validation criteria for adding/editing rows
 * @param {The row object to validate} row 
 * @returns an error message if the row is invalid, or an empty string if the row is valid
 */
export const measurementSchedulesFieldValidation = (row) => {
    if (!row.startDate) {
        return "Start date is required";
    }
    
    if (row.endDate && (row.startDate > row.endDate)) {
        return "Start date must be before end date";
    }

    if (row.startDate < new Date()) {
        return "Start date cannot be in the past";
    }

    if (row.endDate && row.endDate < new Date()) {
        return "End date cannot be in the past";
    }

    if (!row.serviceId || row.serviceId === "") {
        return "Service ID is required";
    }

    if (!row.requester || row.requester === "") {
        return "Requester is required";
    }

    if (!row.period || row.period === "") {
        return "Period is required";
    }

    return "";
};