import { useState, useCallback } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

export const useSignalR = () => {
    const [signalRConnection, setSignalRConnection] = useState(null); // The SignalR connection
    const [errorMessage, setErrorMessage] = useState(null); // The error message
    const [socketMessage, setSocketMessage] = useState(null); // The message

    // Establish the SignalR connection - call and return on render
    const establishSignalRConnection = useCallback((hub) => {
        // Create the connection
        const connection = new HubConnectionBuilder()
            .withUrl(`api/${hub}`)
            .withAutomaticReconnect()
            .build();
        
        // Start the negotiation
        const negotiation = connection.start();

        // Set the connection for reference when negotiation is complete
        negotiation.then(() => {
            setSignalRConnection(connection);
        })
        .catch((e) => {
            setErrorMessage("Connection to SignalR service failed: " + e.message);
        });

        return () => {
            negotiation.then(() => {
                connection.stop();
            })
            .catch((e) => {
                setErrorMessage("Failed to close SignalR connection: " + e.message);
            });
        }
        
    }, [ setErrorMessage ]);

    // Get messages from the SignalR socket - call on render after establishing connection
    const establishSocketListener = useCallback((target) => {
        // Don't run unless the connection has been established
        if (signalRConnection) {
            signalRConnection.on(target, (message) => {
                setSocketMessage(message);
            })
        }
    }, [ signalRConnection, setSocketMessage ]);

    return {
        establishSignalRConnection: establishSignalRConnection,
        establishSocketListener: establishSocketListener,
        errorMessage: errorMessage,
        socketMessage: socketMessage
    };
}