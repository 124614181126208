
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { useState, useEffect, useContext } from 'react';
import { useFetchWithMsal } from "../../Hooks/useFetchWithMsal";
import { useAccount } from "@azure/msal-react";
import { Endpoints } from "../../Constants/Endpoints";
import { MeasurementSchedule, measurementSchedulesFieldValidation } from '../../Models/MeasurementSchedule';
import { SiteIdContext } from '../../Contexts/SiteIdContext';
import { AlertSnackbarContext } from '../../Contexts/AlertSnackbarContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InfoTextDisplay from '../../Components/InfoTextDisplay';
import CMSDataGrid from '../../Components/CMSDataGrid';
import dayjs from "dayjs";
// https://mui.com/x/react-date-pickers/adapters-locale/#set-a-custom-locale
import 'dayjs/locale/en-au';
import '../../Styles/ScheduleMeasurements/schedule-measurements.css';


export default function ScheduleMeasurements() {
    // Hooks
    const { fetchData } = useFetchWithMsal(); // Fetch with MSAL as endpoints are protected
    const account = useAccount(); // The logged in account

    // Contexts
    const selectedSiteId = useContext(SiteIdContext); // get the site ID from context
    const { setErrorMessage, setSuccessMessage } = useContext(AlertSnackbarContext); // use the snackbar for errors

    // States
    const [cols, setCols] = useState(MeasurementSchedule); // Columns for the grid
    const [loading, setLoading] = useState(true); // Whether the service IDs are loading

    /**
     * Default values for a new row
     */
    const newRowMap = [
        {
            field: 'startDate',
            value: new Date(dayjs().add(1, 'day').format())
        },
        {
            field: 'endDate',
            value: null
        },
        {
            field: 'requester',
            value: account.username
        },
        {
            field: 'lastMeasurementPerformedAt',
            value: null
        },
        {
            field: 'totalMeasurementResultsPerformed',
            value: 0
        },

    ];
    
    // On render, fetch the service IDs for the site to set the column definition.
    useEffect(() => {
        // If no site ID is selected, do not fetch the service IDs
        if (selectedSiteId === '' || selectedSiteId === null) {
            return;
        }

        setLoading(true);
        
        // Get Service IDs for site
        fetchData(`${Endpoints.GetServiceIdsForSite}/${selectedSiteId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                // Get an array of options for the service ID column
                const serviceIdOptions = data.map((serviceId) => serviceId.value);
                setCols((prev) => {
                    return prev.map(col => {
                        // modify the serviceId field
                        if (col.field === 'serviceId') {
                            // Set the value options to the service IDs
                            return {
                                ...col,
                                type: 'singleSelect',
                                valueOptions: serviceIdOptions,
                                editable: true
                            }
                        }
                        return col;
                    })
                });
            }
        })
        .catch((error) => {
            setErrorMessage(error.message);
        })
        .finally(() => {
            setLoading(false);
        });
        
    }, [fetchData, selectedSiteId, setCols, setLoading, setErrorMessage]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
            <Box
                className='schedule-measurements-parent-container'
            >
                <Typography variant="h4"
                    className='schedule-measurements-title-text'
                >
                    Measurement Schedules
                </Typography>
                {
                    selectedSiteId === '' || selectedSiteId === null ?
                        <InfoTextDisplay
                            text={"Please select a site to view scheduled measurements"}
                        />
                        :
                        loading ?
                            <CircularProgress
                                className='schedule-measurements-absolute-center'
                                size={70}
                            />
                            :
                            <CMSDataGrid
                                // get scheduled measurements for site ID in context
                                getUrl={`${Endpoints.GetScheduledMeasurementsForSite}/${selectedSiteId}`}
                                baseUrl={Endpoints.MeasurementScheduleRoot}
                                columnsModel={cols}
                                fieldToFocus={"serviceId"}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                                sortKey={"serviceId"}
                                testId='measurement-schedules-grid'
                                newRowMap={newRowMap}
                                validation={measurementSchedulesFieldValidation}
                                noRowsText='No scheduled measurements found for this site'
                        />
                }
            </Box>
        </LocalizationProvider>
    )
}