import GenericCMSSelection from '../../../../Components/GenericCMSSelection';

/**
 * Contains the selections for a measurement type. This
 * is always based on a site ID and service ID.
 */
export default function MeasurementTypeSelection({
    selectedSiteId,
    selectedServiceId,
    selectedMeasurementTypes,
    setSelectedMeasurementTypes,
    measurementTypes,
    measurementTypesLoaded
}) {
    return (
        <GenericCMSSelection
            selectedSiteId={selectedSiteId}
            selectedServiceId={selectedServiceId}
            selectedValue={selectedMeasurementTypes}
            setSelectedValue={setSelectedMeasurementTypes}
            valueTypes={measurementTypes}
            valueTypesLoaded={measurementTypesLoaded}
            label={'Measurement Type'}
            multiple={true}
            selectionLimit={2}
        />   
    )
}