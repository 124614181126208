import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function DeletePrompt({ open, identifierText, handleClose }) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            data-testid="delete-prompt"
        >
            <DialogTitle>
                Confirm Delete    
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are attempting to delete:
                </DialogContentText>
                <List>
                    {
                        identifierText.split(',').map((item, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    padding: '2px 16px'
                                }}
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        fontSize: '1rem'
                                    }}
                                >
                                    {item}
                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List>            
                <DialogContentText>
                    Are you sure you want to delete this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => handleClose(false)}
                    variant={"contained"}
                    data-testid="cancel-delete-prompt"
                >
                    Cancel
                </Button>
                <Button 
                    onClick={() => handleClose(true)}
                    variant={"contained"}
                    color={"error"}
                    data-testid="confirm-delete-prompt"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
      );    
}