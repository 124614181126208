import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import SatelliteIcon from '@mui/icons-material/Satellite';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import '../Styles/selections.css';


// A selection box for the site ID
export default function SiteIdSelection ({
    siteIds,
    selectedSiteId,
    handleSiteIdChange
}) {
    return (
        <FormControl
            className='generic-selection'
            variant='standard'
        >
            <InputLabel 
                id="site-id-label"
                sx={{
                    color: "white",
                    // colour the label when focused
                    '&.Mui-focused': {
                        color: 'white'
                    }
                }}
                // don't bother with the larger form as it will automatically shrink due to the StartAdornment
                shrink={true}
            >
                Site ID
            </InputLabel>
            <Select
                labelId="site-id-label"
                value={selectedSiteId}
                label="Site ID"
                onChange={handleSiteIdChange}
                sx={{
                    // colour the text and icons white
                    color: "white",
                    '.MuiSvgIcon-root ': {
                        fill: "white !important",
                    },
                    '.MuiCircularProgress-root': {
                        color: "white !important"
                    }
                }}
                // add a satellite icon to the start of the input
                startAdornment={
                    <InputAdornment position="start">
                        <SatelliteIcon/>
                    </InputAdornment>
                }
                // add a loading icon to the end of the input
                endAdornment={
                    <InputAdornment position="end">
                        { 
                            siteIds.length === 0 ? 
                                <CircularProgress 
                                    size={16} 
                                    sx={{
                                        marginRight: '1.5rem'
                                    }}
                                /> 
                                : 
                                null 
                        }
                    </InputAdornment>
                }
                disableUnderline={true}
                // disable while loading
                disabled={siteIds.length === 0}
            >
                {
                    siteIds.map((siteId) => {
                        return (
                            <MenuItem 
                                key={siteId.value} 
                                value={siteId.value}
                            >
                                {siteId.value}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}