/**
 * The roles that are allowed to access a certain page.
 */
export const RolesMatrix = {
    LiveData: {
        Roles: ["Measurement.All", "Service.Read"]
    },
    ScheduleMeasurements: {
        Roles: ["Measurement.All,Measurement.Schedule.All", "Service.Read"]
    },
    MeasurementTimeSeries: {
        Roles: ["Measurement.All,Measurement.Read", "Service.Read"]
    },
    MeasurementSpectra: {
        Roles: ["Measurement.All,Measurement.Read", "Service.Read"]
    },
    ServiceConfiguration: {
        Roles: ["Measurement.All", "Test.All", "Service.Read"]
    }
}