/**
 * @fileOverview MeasurementParameters Model
 * @module MeasurementParameters
 * @exports MeasurementParameters
 * @description Contains the model to be used with CMSDataGrid for MeasurementParameters.
 * This is essentially a description of each column.
 * See https://mui.com/x/api/data-grid/grid-col-def/
 */
export const MeasurementParameters = [
    {
        // the data to read
        field: "key",
        // the column header text
        headerName: "Parameter",
        // Fluid width, takes up the remaining space with a weight of 2
        flex: 2
    },
    {
        field: "value",
        headerName: "Value",
        flex: 1.5,
        // whether this column is editable, omission means false
        editable: "false"
    },
    {
        field: "units",
        flex: 1,
        headerName: "Units"
    }
]