/**
 * @file SelectionsPanel.js
 * @desc Contains the selections panel. Contains the selections for a measurement,
 * as well as the measurement parameters/results grid and controls.
 */

import Box from '@mui/material/Box';
import ServiceSelections from '../../../../Components/ServiceSelections';
import MeasurementsSection from './MeasurementsSection';
import '../../../../Styles/panel.css';

/**
 * The selections panel. Contains the selections for a measurement,
 * as well as the measurement parameters/results grid and controls.
 */
export default function LiveDataSelectionsPanel ({
    selectedSiteId,
    serviceIds,
    selectedServiceId,
    handleServiceIdChange,
    startMeasurements,
    updatingQueue,
    queueLength,
    updateQueueLength,
    swapMeasurementsDisplay,
    usedParametersDisplayed, 
    setErrorMessage, 
    setSuccessMessage, 
    setMeasurementParametersApiRef, 
    usedParameters,
    measurementResults,
    continuousSweep,
    setContinuousSweep,
    measurementResultsProcessing,
    stopMeasurements,
    setOverrideParameters,
    overrideParameters,
    continuousMeasurementProcessing
}) {
    return (
        <Box
            className='selections-panel'
        >
            <ServiceSelections
                selectedSiteId={selectedSiteId}
                serviceIds={serviceIds}
                selectedServiceId={selectedServiceId}
                handleServiceIdChange={handleServiceIdChange}
            />
            <MeasurementsSection
                startMeasurements={startMeasurements}
                updatingQueue={updatingQueue}
                queueLength={queueLength}
                updateQueueLength={updateQueueLength}
                swapMeasurementsDisplay={swapMeasurementsDisplay}
                usedParametersDisplayed={usedParametersDisplayed}
                selectedSiteId={selectedSiteId}
                selectedServiceId={selectedServiceId}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                setMeasurementParametersApiRef={setMeasurementParametersApiRef}
                usedParameters={usedParameters}
                measurementResults={measurementResults}
                continuousSweep={continuousSweep}
                setContinuousSweep={setContinuousSweep}
                measurementResultsProcessing={measurementResultsProcessing}
                stopMeasurements={stopMeasurements}
                setOverrideParameters={setOverrideParameters}
                overrideParameters={overrideParameters}
                continuousMeasurementProcessing={continuousMeasurementProcessing}
            />
        </Box>
    )
}