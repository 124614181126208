/**
 * @fileOverview MeasurementResultsPure Model
 * @module MeasurementResultsPure
 * @exports MeasurementResultsPure
 * @description Contains the model to be used with the MUI DataGrid to display Measurement Results
 * received directly from a database. It differs from MeasurementResults.
 */
export const MeasurementResultsPure = [
    {
        field: "name",
        headerName: "Name",
        flex: 2
    },
    {
        field: "realValue",
        headerName: "Value",
        flex: 1.25
    },
    {
        field: "lowerLimit",
        headerName: "Lower Limit",
        flex: 1.25
    },
    {
        field: "upperLimit",
        headerName: "Upper Limit",
        flex: 1.25
    }
]