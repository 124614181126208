import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);

/**
 * This component exists simply to set the license key for MUI X.
 * It should be declared in PageLayout.js.
 * See https://mui.com/x/introduction/licensing/#next-js-app-router
 * This app does not use NextJs, but it uses a similar system.
 */
export default function MuiXLicense() {
  return null;
}
