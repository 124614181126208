import Box from '@mui/material/Box';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { MarkElement } from '@mui/x-charts/LineChart';
import CardDisplay from '../../../Components/CardDisplay';
import '../../../Styles/card-display.css'
import '../../../Styles/panel.css';

/**
 * Contains a basic chart for spectra data.
 * Intended for use with the Measurement Spectra page.
 */
export default function SpectraChartPanel({
    spectraData,
    measurementParameters,
    dataLoading,
    xAxisKey,
    yAxisKey,
}
) {
    // get relevant parameters to modify display
    const amplitude = measurementParameters.find(param => param.name === 'ScalePerDivision')?.realValue;
    const referenceLevel = measurementParameters.find(param => param.name === 'ReferenceLevel')?.realValue;
    const centerFrequency = measurementParameters.find(param => param.name === 'CenterFrequency')?.realValue;
    const markerOffsetFrequency = measurementParameters.find(param => param.name === 'MarkerOffsetFrequency')?.realValue;
    /**
     * Find the index of the center frequency and the marker offset frequency.
     * Since we rely on actual values, we have to find the closest value to the center frequency and the marker offset frequency.
     * We choose the first value that is greater than or equal to the center frequency and the marker offset frequency.
     */
    const centerIndex = centerFrequency ? spectraData.findIndex(spectra => spectra[xAxisKey] >= centerFrequency*1e6) : null;
    const centerOffsetIndex = centerFrequency && markerOffsetFrequency ? spectraData.findIndex(spectra => spectra[xAxisKey] >= (centerFrequency + markerOffsetFrequency)*1e6) : null;

    // custom marker for the chart
    const CustomMarker = (props) => {
        return <MarkElement
            {...props}
            shape='cross'
            // make color dependent on the index
            color={props.dataIndex === centerIndex ? 'red' : 'orange'}
        />
    }
    
    return (
        <Box
            className='content-panel'           
        >
            {
                spectraData.length === 0 || dataLoading ?
                    <CardDisplay
                        text={dataLoading ? "Loading..." : "Select a spectrum!"}
                        loading={dataLoading}
                        Icon={StackedLineChartIcon}
                    />
                    :
                    <LineChart
                        xAxis={[
                            { 
                                dataKey: xAxisKey,
                                label: 'MHz',
                                labelStyle: {
                                    fontSize: 20
                                },
                                // the data should be in Hz
                                valueFormatter: (value) => {
                                    return (value / 1e6).toString();
                                }, 
                            }
                        ]}
                        yAxis={[
                            {
                                // if null, will revert to default max
                                max: referenceLevel,
                                // find the minimum value of the spectra data and subtract 10
                                min: Math.min(...spectraData.map(spectra => spectra[yAxisKey])) - 10,
                                // if null, will revert to default min step
                                tickMinStep: amplitude
                            }
                        ]}
                        series={[
                            {
                                dataKey: yAxisKey,
                                connectNulls: true,
                                label: 'dB',
                                showMark: 
                                    // if either index is defined, only show the mark at the center frequency and the marker offset frequency
                                    centerIndex || centerOffsetIndex ?
                                        ({ index }) => {
                                            // only show the mark at the center frequency and the marker offset frequency
                                            return index === centerIndex || index === centerOffsetIndex;
                                        }
                                        :
                                        true
                            },
                        ]}
                        slots={{
                            // show the custom marker if either index is defined
                            mark: centerIndex || centerOffsetIndex ? CustomMarker : null
                        }}
                        dataset={spectraData}
                        margin={{ left: 75 }}
                    /> 
            }
        </Box>
    )
}