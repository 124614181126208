import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import CircularProgress from '@mui/material/CircularProgress';
import Unauthorised from '../Pages/Unauthorised';

/**
 * Logs the user in if they are not already logged in, and checks if they have the required roles.
 * If they do, then the children are rendered. Otherwise, the Unauthorised component is rendered.
 * Adopted from https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/5-AccessControl/1-call-api-roles/SPA/src/components/RouteGuard.jsx
 */
export default function RouteGuard ({ ...props }) {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [missingRoles, setMissingRoles] = useState();

    const currentAccount = instance.getActiveAccount();

    const onLoad = useCallback(async () => {
        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            const missingRoles = [];
            // check how many roles the user has in common with the roles required to view the page
            props?.roles?.forEach((role) => {
                // a role may be split into options using ","
                const roleOptions = role.split(',');

                // check if the user has one of the given options
                if (!roleOptions.some(option => currentAccount.idTokenClaims['roles'].includes(option))){
                    /**
                     * If not, then add their missing role.
                     * If there are multiple, separate with "or" for clarity
                     */ 
                    missingRoles.push(roleOptions.join(" or "));
                }
            })

            // if the user has at least one of the required roles, then they are authorized
            if (missingRoles.length === 0) {
                setIsAuthorized(true);
            }

            else {
                // stringify array as setting state with an array triggers a re-render, causing an infinite loop
                setMissingRoles(JSON.stringify(missingRoles));
            }
        }

        setIsLoading(false);
    }, [currentAccount, props.roles]);

    useEffect(() => {
        onLoad();
    }, [onLoad, instance, currentAccount]);

    /**
     * Don't return anything until we've checked if the user is authorized or not.
     * Determining the authorization status is an asynchronous operation, so we need to wait for it to finish.
     * Otherwise, we might render a glimpse of the protected component before displaying the unauthorised component.
     */
    if (isLoading) {
        return (
            <CircularProgress sx={{
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}/>
        )
    }

    return (
        isAuthorized ? (
            props.children
        ) : (
            <Unauthorised roles={JSON.parse(missingRoles)}/>
        )
    );
};