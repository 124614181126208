import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import '../Styles/ScheduleMeasurements/info-text-display.css';

// A component to display info text in a card
export default function InfoTextDisplay({ text }){
    return (
        <Box
            className='display-card-container'
        >
            <Card
                sx={{
                    maxWidth: '475px',
                }}
            >
                <CardContent
                    className='display-card-content'
                >
                    <InfoIcon
                        color='primary'
                        sx={{
                            marginRight: '1rem',
                        }}
                    />
                    <Typography 
                        variant="body1"
                    >
                        { text }
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
}